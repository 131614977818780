import { multipleChoiceValidar } from './renders/MultipleChoice'
import { archivoValidar } from './renders/Archivo'
import { videoValidar } from './renders/Video'
import { hipervinculoValidar } from './renders/Hipervinculo'
import { imagenValidar } from './renders/Imagen'
import { textoValidar } from './renders/Texto'
import { codigoValidar } from './renders/Codigo'
import { completarFraseValidar } from './renders/CompletarFrase'

export default (nombre, principal) => {
	switch (nombre) {
		case 'Texto':
			return textoValidar(principal)
		case 'Imagen':
			return imagenValidar(principal)
		case 'Archivo':
			return archivoValidar(principal)
		case 'Hipervinculo':
			return hipervinculoValidar(principal)
		case 'Video':
			return videoValidar(principal)
		case 'Pregunta multiple choice':
			return multipleChoiceValidar(principal)
		case 'Codigo':
            return codigoValidar(principal)
        case 'Completar la frase':
            return completarFraseValidar(principal)
		default:
			return false
	}
}
