import React, { useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, makeStyles, TextField } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'
import { tamImagen, validateSize } from '../../../../../constants'
import { openSnack } from '../../../../../actions/feedback/openSnack'
import { encodeBase64 } from '../../../../../api/encoding'

export function ImagenPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<div style={{ width: '100%', display: 'flex' }}>
			<TextField
				className={classes.input}
				style={{ width: '70%' }}
				name='archivo'
				value={principal.archivo ? principal.archivo : ''}
				variant='filled'
				label='Imagen'
				onChange={() => {}}
				required
				error={moduloError && !principal.archivo}
			/>
			<Button
				style={{ width: '28%', marginLeft: '2%', alignSelf: 'center', marginBottom: 10 }}
				variant='contained'
				color={moduloError && !principal.encoded ? 'error' : 'primary'}
				component='label'
			>
				Subir imagen
				<input
					style={{ display: 'none' }}
					id='path'
					type='file'
					onChange={({ target: { files } }) => {
						const file = files[0]
						if (file) {
							if (validateSize(file, tamImagen * 1024 * 1024))
								encodeBase64(file, f =>
									dispatch(
										onChangeModulo({
											name: 'formulario',
											value: {
												...formulario,
												principal: {
													...principal,
													archivo: file.name,
													encoded: f.target.result
												}
											}
										})
									)
								)
							else
								dispatch(
									openSnack({
										texto: 'El tamaño del archivo no puede superar los ' + tamImagen + 'mb',
										tipo: 'info'
									})
								)
						}
					}}
				/>
			</Button>
		</div>
	)
}

const useStyles = makeStyles(theme => ({
	img: {
		'&:hover': {
			cursor: 'pointer'
		}
	}
}))

export function ImagenRender(props) {
	const [ open, setOpen ] = useState(false)
	const [ zoom, setZoom ] = useState(false)
	const classes = useStyles()

    const { modulo } = props

    const openImgNewTab = () => {
        window.open(modulo.url)
    }

	return (
		<React.Fragment>
			<img className={classes.img} alt='imagen' width='25%' src={modulo.url} onClick={() => setOpen(true)} />
			<Dialog open={open} onClose={() => setOpen(false)} maxWidth='lg' fullWidth={zoom}>
				<DialogTitle title={modulo.titulo} />
				<DialogContent>
					<img className={classes.zoom} style={{cursor: zoom? "zoom-out" : 'zoom-in'}} alt='imagen' width={zoom? '100%' : '100%'} src={modulo.url} onClick={() => setZoom(!zoom  )} />
                    <Button color='primary' onClick={openImgNewTab}>Ver imagen original</Button>
                </DialogContent>
			</Dialog>
		</React.Fragment>
	)
}

export const imagenValidar = principal => principal.archivo && principal.encoded
