import React from 'react'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core'

import Navbar from './Navbar'
import Formulario from './Formulario'
import Animacion from './Animacion'
import { green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
	/* root: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		background:
			'linear-gradient(135deg, rgba(27,140,205,1) 0%, rgba(65,156,209,1) 25%, rgba(100,166,204,1) 75%, rgba(27,140,205,1) 100%)'
    }, */
	root: `
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;`,
	paper: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		flexDirection: 'column',
		padding: 30,
		width: '20%',
		height: '25%'
	}
}))

export default function Login() {
    const classes = useStyles()
    
    const theme = createMuiTheme({
		palette: {
			type: 'light',
			primary: { main: '#1b346b' },
			secondary: { main: red[500] },
			success: { main: green[600] },
			error: { main: red[600] },
		},
		props: {
			MuiButton: {
				disableElevation: true,
				style: { textTransform: 'none' }
			},
			MuiPaper: {
				variant: 'outlined'
			}
		}
	})

	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<Navbar isTop={true} />
				<Formulario />
				<Animacion />
			</div>
		</ThemeProvider>
	)
}
