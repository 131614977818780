export const LOGIN = 'login'
export const SET_CURSO = 'setCurso'
export const SET_UNIDAD = 'setUnidad'
export const LOGOUT = 'logout'
export const SET_CURSOS = 'setCursos'
export const SET_USUARIOS = 'setUsuarios'
export const SET_GRUPOS = 'setGrupos'
export const SET_HIJOS = 'setHijos'
export const SET_PERMISOS = 'setPermisos'
export const OPEN_SNACK = 'openSnack'
export const CLOSE_SNACK = 'closeSnack'
export const START_LOADING = 'startLoading'
export const END_LOADING = 'endLoading'
export const SET_METADATA = 'setMetadata'
export const ONCHANGE_MODULO = 'onChangeModulo'
export const ONCHANGE_RESPUESTA_MODULO = 'onChangeRespuestaModulo'
export const CLEAN_MODULOS = 'cleanModulos'
export const CHANGE_COLOR = 'changeColor'
export const SET_SETTINGS = 'setSettings'
export const TOGGLE_DARK = 'toggleDark'

//en megabyte
export const tamIcono = 1
export const tamArchivo = 15
export const tamImagen = 5

export const validateSize = (e, size) => {
    return e.size < size
}

export const isAlfaNum = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9.]*$/

export const faqType = [ 'Pregunta', 'Sugerencia' ]

// constantes de estilo