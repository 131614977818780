import React from 'react'
import { ArchivoRender } from './renders/Archivo'
import { ImagenRender } from './renders/Imagen'
import { TextoRender } from './renders/Texto'
import { HipervinculoRender } from './renders/Hipervinculo'
import { VideoRender } from './renders/Video'
import { MultipleChoiceRender } from './renders/MultipleChoice'
import { CodigoRender } from './renders/Codigo'
import { CompletarFraseRender } from './renders/CompletarFrase'
import { SubirActividadResueltaRender } from './renders/SubirActividadResuelta'

export default function RenderCreated(props){
    const { modulo } = props

    switch (modulo.tipo) {
        case 'Texto':
            return <TextoRender {...props} />
        case 'Imagen':
            return <ImagenRender {...props} />
        case 'Archivo':
            return <ArchivoRender {...props} />
        case 'Hipervinculo':
            return <HipervinculoRender {...props} />
        case 'Video': 
            return <VideoRender {...props} />
        case 'Pregunta multiple choice':
            return <MultipleChoiceRender {...props} />
        case 'Codigo':
            return <CodigoRender {...props} />
        case 'Completar la frase': 
            return <CompletarFraseRender {...props} />
        /* case 'Subir actividad resuelta': 
            return <SubirActividadResueltaRender {...props} /> */
        default:
            return null;
    }
}