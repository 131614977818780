import React, { useEffect, useState } from 'react'
import './unidad.css'
import {
	makeStyles,
	Typography,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Button,
	Fab,
	Grid,
	ButtonBase,
	Card,
	Slide,
	Popover,
	Tooltip,
	useTheme,
	Box,
	MenuItem,
	TextField,
	Divider
} from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Close, Add, ArrowLeft, ArrowRight, Edit, Delete, ArrowBack, ArrowUpward } from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link, useHistory } from 'react-router-dom'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

import { apiPost, apiGetWithParams, apiPut, apiDelete } from '../../../api'
import { urlModulos, urlCurso, urlUnidades, urlRespuestas, urlFaq } from '../../../api/urls'
import { green, red, lightBlue } from '@material-ui/core/colors'
import { setCurso } from '../../../actions/curso/setCurso'
import { setUnidad } from '../../../actions/unidad/setUnidad'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { permisos, hasPermission } from '../../../constants/permisos'
import modulosDisponibles from './Modulos/modulosDisponibles'
import RenderDialog from './Modulos/RenderDialog'
import RenderCreated from './Modulos/RenderCreated'
import validar from './Modulos/validar'
import { onChangeModulo } from '../../../actions/modulos/onChangeModulo'
import { cleanModulos } from '../../../actions/modulos/cleanModulos'
import { faqType } from '../../../constants'

const indiceWidth = '25vw'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	indexMenu: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '25%',
		marginTop: 0,
		margin: 10,
		backgroundColor: 'transparent'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	dialogContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		overflowX: 'hidden'
	},
	input: {
		marginBottom: 10
	},
	addButton: {
		position: 'fixed',
		right: theme.spacing(3),
		bottom: theme.spacing(6) + 56,
		zIndex: 1000
	},
	backTopButton: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
		zIndex: 1000,
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.primary.main
	},
	moduloItem: {
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	item: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		backgroundColor: '#e3e3e3',
		marginBottom: 10,
		paddingBottom: 1,
		borderBottom: '1px solid #848484'
	},
	itemSeleccionado: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		backgroundColor: lightBlue[200],
		marginBottom: 10,
		borderBottom: '2px solid ' + lightBlue[500]
	},
	itemCorrecto: {
		borderLeft: '3px solid ' + green[600],
		borderRight: '3px solid ' + green[600],
		borderTop: '3px solid ' + green[600],
		borderBottom: '3px solid ' + green[600]
	},
	itemIncorrecto: {
		borderLeft: '3px solid ' + red[500],
		borderRight: '3px solid ' + red[500],
		borderTop: '3px solid ' + red[500],
		borderBottom: '3px solid ' + red[500]
	},
	tituloModulo: {
		//marginBottom: 10,
		color: theme.palette.title.main
	},
	modulos: {
		display: 'flex',
		flexDirection: 'column',
		flex: 3,
		marginRight: indiceWidth
	},
	modulo: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px 2px'
	},
	card: {
		padding: 10,
		marginBottom: 10,
		marginRight: 10
	},
	indexButton: {
		position: 'fixed',
		right: 0,
		display: 'flex',
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		height: 50,
		width: 29,
		minWidth: 25,
		padding: 1,
		alignItems: 'center',
		color: 'white',
		zIndex: 10000
	},
	moduloTitulo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row'
	},
	moduloOpciones: { flexGrow: 1, justifyContent: 'flex-end', display: 'flex' },
	popover: {
		pointerEvents: 'none'
	},
	paper: {
		padding: theme.spacing(1)
	},
	notSelectable: {
		'-webkit-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		'user-select': 'none'
	},
	editTooltip: {
		backgroundColor: 'white',
		color: 'black',
		maxWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid ' + theme.palette.primary.main
	},
	editTooltipArrow: {
		color: theme.palette.primary.main
	},
	faqContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 15,
		marginBottom: 15
    },
    unitTitle: {
        color: theme.palette.colorTitle.main
    }
}))

export default function Unidad() {
	const [ state, setState ] = useState({
		nombre: '',
		openIndice: true,
		listaModulos: [],
		unidadSiguiente: {}
	})
	const [ doFetch, setDoFetch ] = useState(0)
	const { titulo, formulario, openNew, pagina, paraEliminar, openDelete, edit, modulo } = useSelector(
		state => state.modulos.editing
	)
	const [ faq, setFaq ] = useState({ open: false, title: '', comment: '', type: 'Pregunta' })
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const unidad = useSelector(state => state.unidad.unidad)
	const curso = useSelector(state => state.curso.curso)
	const rol = useSelector(state => state.login.rol)
    const infoPermisos = useSelector(state => state.permisos)
    const iconColor = useSelector(state => state.theme.prefersDarkMode) ? 300 : 500
	const history = useHistory()

	const getModulos = () => {
		dispatch(startLoading())
		apiGetWithParams(urlModulos, { unidad: unidad.id }).then(r => {
			if (r.status === 'OK') {
				let listaModulos = r.data
				listaModulos.sort((a, b) => {
					if (a.data.principal.indice > b.data.principal.indice) return 1
					if (a.data.principal.indice < b.data.principal.indice) return -1
					return 0
				})
				setState(ps => ({ ...ps, listaModulos }))
			} else dispatch(openSnack({ texto: 'Error al obtener modulos', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	useEffect(
		() => {
			dispatch(startLoading())
			apiGetWithParams(urlCurso, { id: curso.id }).then(r => {
				if (r.status === 'OK') dispatch(setCurso(r.data))
				else dispatch(openSnack({ texto: 'Error al obtener los datos del curso', tipo: 'error' }))
				dispatch(endLoading())
			})
			dispatch(startLoading())
			apiGetWithParams(urlUnidades, { curso: curso.id }).then(r => {
				if (r.status === 'OK') {
					let actual = {}
					let siguiente = {}
					r.data.map(e => {
						if (e.id.toString() === unidad.id.toString()) actual = e
					})
					r.data.map(e => {
						//me fijo que exista el indice siguiente en el arreglo despues de encotrar la unidad que corresponde en el map anterior
						if (e.indice === actual.indice + 1) siguiente = e
					})
					dispatch(setUnidad(actual))
					setState(ps => ({ ...ps, unidadSiguiente: siguiente }))
				} else dispatch(openSnack({ texto: 'Error al obtener los datos de la unidad', tipo: 'error' }))
				dispatch(endLoading())
			})
			getModulos()
			// eslint-disable-next-line
		},
		[ doFetch ]
	)

	const cambiarPagina = modulo => {
		dispatch(onChangeModulo({ name: 'pagina', value: 2 }))
		dispatch(onChangeModulo({ name: 'modulo', value: modulo }))
	}

	const backToTop = () => {
		window.scrollTo(0, 0)
	}

	const cleanFormulario = () => {
		dispatch(cleanModulos())
	}

	const onGuardarModulo = () => {
		const { listaModulos } = state
		if (validar(modulo.nombre, formulario.principal)) {
			// EDITAR
			dispatch(startLoading())
			if (!edit)
				apiPost(urlModulos, {
					data: {
						...formulario,
						principal: { ...formulario.principal, indice: listaModulos.length * 100 },
						tipo: modulo.nombre
					},
					unidadId: unidad.id,
					titulo
				}).then(r => {
					if (r.status === 'OK') {
						getModulos()
						cleanFormulario()
						dispatch(openSnack({ texto: 'Modulo creado con éxito', tipo: 'success' }))
					} else dispatch(openSnack({ texto: 'Error al crear modulo', tipo: 'error' }))
					dispatch(endLoading())
				})
			else
				apiPut(urlModulos, { data: { id: formulario.id, titulo, data: formulario }, bulk: false }).then(r => {
					if (r.status === 'OK') {
						getModulos()
						cleanFormulario()
						dispatch(openSnack({ texto: 'Modulo modificado con éxito', tipo: 'success' }))
					} else dispatch(openSnack({ texto: 'Error al modificar el modulo', tipo: 'error' }))
					dispatch(endLoading())
				})
		} else {
			dispatch(openSnack({ texto: 'Uno o mas campos obligatorios están vacíos', tipo: 'info' }))
			dispatch(onChangeModulo({ name: 'moduloError', value: true }))
		}
	}

	const renderPage = () => {
		return pagina === 1 ? (
			<Grid justify='center' alignItems='center' container spacing={0} wrap='wrap'>
				{modulosDisponibles.map((m, i) => (
					<Grid key={'gr324-' + i} item style={{ width: '40%', padding: 20 }}>
						<ButtonBase
							component={'div'}
							className={classes.moduloItem}
							variant='text'
							onClick={() => cambiarPagina(m)}
						>
							<m.icono key={'icon324-' + i} style={{ fontSize: 50, color: m.color[iconColor] }} />
							<Typography key={'ty324-' + i}>{m.nombre}</Typography>
						</ButtonBase>
					</Grid>
				))}
			</Grid>
		) : (
			<RenderDialog classes={classes} modulo={modulo} />
		)
	}

	const guardarRespuesta = data => {
		dispatch(startLoading())
		apiPost(urlRespuestas, data).then(r => {
			if (r.status === 'OK') {
				getModulos()
				dispatch(openSnack({ texto: 'Respuesta guardada', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al guardar respuesta', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const renderModulo = modulo => {
		const m = modulosDisponibles.filter(e => modulo.data.tipo === e.nombre) // obtengo el modulo de la lista, ya se que me va a devolver uno solo como maximo
		if (m[0])
			return (
				<RenderCreated
					classes={classes}
					modulo={{ ...modulo.data, id: modulo.id, titulo: modulo.titulo }}
					guardarRespuesta={guardarRespuesta}
				/>
			)
		else return null
	}

	const renderIndiceItem = modulo => {
		const m = modulosDisponibles.filter(e => modulo.data.tipo === e.nombre) // obtengo el modulo de la lista, ya se que me va a devolver uno solo como maximo
		if (m[0]) {
			const mod = m[0]
			return (
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
					<mod.icono style={{ color: mod.color[iconColor], marginRight: 5 }} />
					<Typography>
						{modulo.titulo ? modulo.titulo : modulo.data.tipo}
					</Typography>
				</div>
			)
		} else return null
	}

	const guardarIndice = data => {
		apiPut(urlModulos, { data, bulk: true }).then(r => {
			if (r.status !== 'OK')
				dispatch(
					openSnack({
						texto: 'Puede que algunos cambios en el indice no se hayan guardado correctamente',
						tipo: 'warning'
					})
				)
		})
	}

	const closeFaq = () => {
		setFaq({ title: '', open: false, comment: '', type: 'Pregunta' })
	}

	const sendFaq = () => {
		const { comment, type, title } = faq
		if (type)
			if (comment.length > 15) {
				dispatch(startLoading())
				apiPost(urlFaq, { idUnidad: unidad.id, comment, type, title }).then(r => {
					if (r.status === 'OK') {
						closeFaq()
						dispatch(openSnack({ texto: 'Mensaje enviado exitosamente', tipo: 'success' }))
					}
					dispatch(endLoading())
				})
			} else dispatch(openSnack({ texto: 'El comentario debe tener al menos 15 caracteres', tipo: 'warning' }))
		else dispatch(openSnack({ texto: 'Seleccione un tipo de mensaje', tipo: 'warning' }))
	}

	const onOpenEditModulo = modulo => {
		const m = modulosDisponibles.filter(m => m.nombre === modulo.data.tipo)
		dispatch(
			onChangeModulo({
				name: 'formulario',
				value: {
					...modulo.data,
					id: modulo.id
				}
			})
		)
		dispatch(
			onChangeModulo({
				name: 'openNew',
				value: true
			})
		)
		dispatch(
			onChangeModulo({
				name: 'pagina',
				value: 2
			})
		)
		dispatch(
			onChangeModulo({
				name: 'titulo',
				value: modulo.titulo
			})
		)
		dispatch(
			onChangeModulo({
				name: 'modulo',
				value: m[0]
			})
		)
		dispatch(
			onChangeModulo({
				name: 'edit',
				value: true
			})
		)
	}

	const onOpenDeleteModulo = modulo => {
		dispatch(onChangeModulo({ name: 'openDelete', value: true }))
		dispatch(onChangeModulo({ name: 'paraEliminar', value: modulo }))
	}

	const onDeleteModulo = () => {
		dispatch(startLoading())
		apiDelete(urlModulos, { moduloId: paraEliminar.id }).then(r => {
			if (r.status === 'OK') {
				dispatch(cleanModulos())
				getModulos()
				dispatch(openSnack({ texto: 'Modulo eliminado con éxito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al eliminar modulo', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const redirectUnidad = () => {
		const urls = window.location.pathname.split('/')
		let redirect = '/' + state.unidadSiguiente.id
		for (let i = urls.length - 2; i >= 0; i--) redirect = (i === 0 ? '' : '/') + urls[i] + redirect
		dispatch(setUnidad(state.unidadSiguiente))
		history.push(redirect)
        setDoFetch(doFetch + 1)
        backToTop()
	}

	//DRAG AND DROP METHODS

	const reorder = (list, startIndex, endIndex) => {
		var result = Array.from(list)
		const [ removed ] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		result.map((e, i) => (e.data.principal.indice = i))
		guardarIndice(result)
		return result
	}

	const onDragEnd = result => {
		// dropped outside the list
		if (!result.destination) {
			return
		}

		const listaModulos = reorder(state.listaModulos, result.source.index, result.destination.index)

		setState(ps => ({
			...ps,
			listaModulos
		}))
	}

	const renderEliminar = () => {
		return (
			<React.Fragment>
				<Typography>{paraEliminar.titulo || paraEliminar.data.tipo}</Typography>
			</React.Fragment>
		)
	}

	const { listaModulos, openIndice, anchorEl } = state
	return (
		<div className={classes.root}>
			<Popover
				id='mouse-over-popover'
				className={classes.popover}
				classes={{
					paper: classes.paper
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				onClose={() => setState(ps => ({ ...ps, anchorEl: false }))}
				disableRestoreFocus
				elevation={1}
			>
				<Typography>Obligatorio</Typography>
			</Popover>
			<div className={classes.modulos} style={{ marginRight: openIndice ? indiceWidth : 0 }}>
				{hasPermission(rol, infoPermisos, permisos.CREAR_MODULO) ? (
					<Fab
						className={classes.addButton}
						size='large'
						color='primary'
						onClick={() => dispatch(onChangeModulo({ name: 'openNew', value: true }))}
					>
						<Add />
					</Fab>
				) : null}
				<Fab className={classes.backTopButton} size='large' color='inherit' onClick={backToTop}>
					<ArrowUpward />
				</Fab>
				<Button
					color='primary'
					variant='contained'
					className={classes.indexButton}
					onClick={() => setState(ps => ({ ...ps, openIndice: !openIndice }))}
				>
					{openIndice ? <ArrowRight fontSize={'large'} /> : <ArrowLeft fontSize={'large'} />}
				</Button>
				<Card className={classes.card}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<Link to={'/dashboard/miscursos/curso/' + curso.id}>
							<IconButton>
								<ArrowBack />
							</IconButton>
						</Link>
						<Typography variant='h5' className={classes.unitTitle}>
							{unidad.nombre && curso.nombre ? `Unidad ${unidad.nombre} (${curso.nombre})` : 'Cargando..'}
						</Typography>
					</div>
					<Typography variant='subtitle2'>{unidad.descripcion}</Typography>
				</Card>
				{listaModulos.map((m, i) => (
					<Card key={'div-2' + i} className={clsx(classes.modulo, classes.card)}>
						<div className={classes.moduloTitulo}>
							{m.titulo ? (
								<Typography variant='h5' className={classes.tituloModulo}>
									{m.titulo}
								</Typography>
							) : (
								''
							)}
							{m.data.obligatorio ? (
								<Tooltip
									classes={{ tooltip: classes.editTooltip, arrow: classes.editTooltipArrow }}
									title='Obligatorio'
									arrow
								>
									<ErrorOutlineOutlinedIcon
										style={{ marginLeft: 5 }}
										fontSize='small'
										color='error'
									/>
								</Tooltip>
							) : null}
							<div className={classes.moduloOpciones}>
								{hasPermission(rol, infoPermisos, permisos.EDITAR_MODULO) ? m.data.tipo ===
								'Completar la frase' ? (
									<Tooltip
										classes={{ tooltip: classes.editTooltip, arrow: classes.editTooltipArrow }}
										title='Edicion no disponible en éste modulo'
										arrow
									>
										<span>
											<IconButton color='primary' disabled={true} onClick={() => {}}>
												<Edit />
											</IconButton>
										</span>
									</Tooltip>
								) : (
									<IconButton color='primary' onClick={() => onOpenEditModulo(m)}>
										<Edit />
									</IconButton>
								) : null}
								{hasPermission(rol, infoPermisos, permisos.ELIMINAR_MODULO) ? (
									<IconButton onClick={() => onOpenDeleteModulo(m)}>
										<Delete color='error' />
									</IconButton>
								) : null}
							</div>
						</div>
						{renderModulo(m)}
					</Card>
				))}
				{state.unidadSiguiente.nombre ? (
					<Box className={classes.faqContainer}>
						<Button variant='contained' color='primary' onClick={redirectUnidad}>
							Continuar curso en la siguiente unidad
						</Button>
					</Box>
				) : null}
				<Box className={classes.faqContainer}>
					<Typography paragraph variant='h6'>
						¿Tenes alguna pregunta o queres dejar una sugerencia sobre esta unidad?
					</Typography>
					<Button variant='contained' color='primary' onClick={() => setFaq({ ...faq, open: true })}>
						¡Dejanos un comentario!
					</Button>
				</Box>
				<Dialog fullWidth={true} disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={faq.open}>
					<DialogTitle>Preguntas y sugerencias</DialogTitle>
					<Divider />
					<DialogContent className={classes.dialogContent}>
						<TextField
							variant='filled'
							value={faq.type || ''}
							name='faqtype'
							onChange={e => setFaq({ ...faq, type: e.target.value })}
							label='Comentario'
							select
							margin='dense'
							required
						>
							{faqType.map(v => (
								<MenuItem value={v} key={v}>
									{v}
								</MenuItem>
							))}
						</TextField>
						<TextField
							variant='filled'
							value={faq.title || ''}
							margin='dense'
							name='faqtitle'
							onChange={e => setFaq({ ...faq, title: e.target.value })}
							label='Asunto (Opcional)'
						/>
						<TextField
							margin='dense'
							variant='filled'
							value={faq.comment || ''}
							name='faqcomment'
							onChange={e => setFaq({ ...faq, comment: e.target.value })}
							placeholder='Comentario...'
							multiline
							rows={3}
							rowsMax={8}
							required
						/>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button color='secondary' onClick={closeFaq}>
							Cancelar
						</Button>
						<Button variant='contained' color='primary' onClick={sendFaq}>
							Enviar
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					scroll='paper'
					disableBackdropClick
					disableEscapeKeyDown
					maxWidth={'lg'}
					open={openNew || openDelete}
				>
					<DialogTitle disableTypography>
						<Typography variant='h6'>
							{edit ? 'Editar' : openDelete ? 'Eliminar' : 'Agregar'} modulo
							{pagina === 2 ? ': ' + modulo.nombre : ''}
						</Typography>
						<IconButton
							aria-label='close'
							className={classes.closeButton}
							onClick={() => {
								cleanFormulario()
							}}
						>
							<Close color='error' />
						</IconButton>
					</DialogTitle>
					<DialogContent className={classes.dialogContent} dividers={true}>
						{openDelete ? renderEliminar() : renderPage()}
					</DialogContent>
					{openDelete ? (
						<DialogActions>
							<Button color='error' variant='outlined' onClick={() => onDeleteModulo()}>
								Eliminar
							</Button>
						</DialogActions>
					) : pagina === 2 ? (
						<DialogActions>
							{edit ? null : (
								<Button variant='text' onClick={() => cleanFormulario()}>
									Atras
								</Button>
							)}
							<Button color='primary' variant='contained' onClick={() => onGuardarModulo()}>
								Guardar
							</Button>
						</DialogActions>
					) : null}
				</Dialog>
			</div>
			<div className={'indice'}>
				<Slide direction='left' in={openIndice} mountOnEnter unmountOnExit>
					{hasPermission(rol, infoPermisos, permisos.ALTERAR_INDICE_MODULO) ? (
						<div>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId='droppable'>
									{(provided, snapshot) => (
										<div
											className={clsx(classes.indexMenu, classes.card)}
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											<Typography className={classes.unitTitle} variant='h5'>
												Índice
											</Typography>
											{listaModulos.map((m, index) => (
												<Draggable key={m.id} draggableId={`${m.id}`} index={index}>
													{(provided, snapshot) => (
														<Card
															key={'div-2' + index}
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={{
																padding: 3,
																margin: 5,
																color: snapshot.isDragging ? 'white' : theme.palette.getContrastText(theme.palette.background.paper),
																backgroundColor: snapshot.isDragging
																	? theme.palette.primary.main
																	: theme.palette.background.paper,
																...provided.draggableProps.style
															}}
														>
															{renderIndiceItem(m)}
														</Card>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
					) : (
						<div className={clsx(classes.indexMenu, classes.card)}>
							<Typography color='primary' variant='h5'>
								Índice
							</Typography>
							{listaModulos.map((m, index) => (
								<Card
									key={'div-2' + index}
									style={{
										padding: 3,
										margin: 5,
									}}
								>
									{renderIndiceItem(m)}
								</Card>
							))}
						</div>
					)}
				</Slide>
			</div>
		</div>
	)
}
