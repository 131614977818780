import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	makeStyles,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	Card,
	Avatar,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	Fab,
	MenuItem,
	ListItemSecondaryAction,
	Tooltip,
	Menu,
	useTheme
} from '@material-ui/core'
import { Close, Add, Delete, Edit, ArrowBack, MoreVert, Done } from '@material-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import colorInterpolate from 'color-interpolate'

import { apiPost, apiGetWithParams, apiPut, apiDelete } from '../../../api'
import { urlUnidades, urlCurso } from '../../../api/urls'
import { setUnidad } from '../../../actions/unidad/setUnidad'
import { setCurso } from '../../../actions/curso/setCurso'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { permisos, hasPermission } from '../../../constants/permisos'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const useStyles = makeStyles(theme => ({
	root: {
		paddingBottom: 20
	},
	list: {
		width: '100%',
		backgroundColor: 'transparent'
	},
	inline: {
		display: 'inline'
	},
	listItem: {
		borderRadius: 20,
		backgroundColor: theme.palette.background.paper,
		marginBottom: 10
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	dialogContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	input: {
		marginBottom: 10
	},
	addButton: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3)
	},
	editTooltip: {
		backgroundColor: 'white',
		color: 'black',
		maxWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid ' + theme.palette.primary.main
	},
	editTooltipArrow: {
		color: theme.palette.primary.main
	},
	menuItemText: {
		marginLeft: 5
	}
}))

const inter = colorInterpolate([ '#f44336', '#ff9800', '#ffeb3b', '#cddc39', '#8bc34a', '#4caf50' ])

const getProgressColor = value => {
	return inter(value / 100)
}

export default function MiCurso() {
	const [ state, setState ] = useState({
		nombre: '',
		descripcion: '',
		openNew: false,
		padreId: -1,
		unidades: [],
		cargando: true,
		redirect: undefined,
		edit: false,
		unidadActual: false,
		openDelete: false,
		unidadError: false,
		anchorel: null
	})
	const curso = useSelector(state => state.curso.curso)
	const infoPermisos = useSelector(state => state.permisos)
	const rol = useSelector(state => state.login.rol)
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const theme = useTheme()

	const getUnidades = () => {
		dispatch(startLoading())
		apiGetWithParams(urlUnidades, { curso: curso.id }).then(r => {
			if (r.status === 'OK') {
				const ordenado = r.data.sort((a, b) => {
					if (a.indice > b.indice) return 1
					else return -1
				})
				setState(ps => ({ ...ps, unidades: ordenado, cargando: false }))
			} else dispatch(openSnack({ texto: 'Error al obtener los datos de las unidades', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	useEffect(() => {
		dispatch(startLoading())
		apiGetWithParams(urlCurso, { id: curso.id }).then(r => {
			if (r.status === 'OK') dispatch(setCurso(r.data))
			else dispatch(openSnack({ texto: 'Error al obtener los datos del curso', tipo: 'error' }))
			dispatch(endLoading())
		})
		getUnidades()
		if (hasPermission(rol, infoPermisos, permisos.EDITAR_UNIDAD))
			dispatch(
				openSnack({
					texto: 'Para reordenar las unidades, arrastre y suelte la unidad en la posicion deseada',
					tipo: 'info'
				})
			)
		//eslint-disable-next-line
	}, [])

	const setRedirect = (unidad, redirect) => {
		dispatch(setUnidad(unidad, history.push(redirect)))
	}

	const onCrearUnidad = () => {
		const { nombre, descripcion, padreId, edit, unidadActual } = state
		if (nombre && descripcion) {
			dispatch(startLoading())
			if (!edit)
				apiPost(urlUnidades, { cursoId: curso.id, nombre, descripcion, padreId, indice: state.unidades.length }).then(r => {
					if (r.status === 'OK') {
						handleClose()
						getUnidades()
						dispatch(openSnack({ texto: 'Unidad creada con éxtito', tipo: 'success' }))
					} else dispatch(openSnack({ texto: 'Error al crear unidad', tipo: 'error' }))
					dispatch(endLoading())
				})
			else
				apiPut(urlUnidades, { nombre, descripcion, unidadId: unidadActual.id, padreId }).then(r => {
					if (r.status === 'OK') {
						handleClose()
						getUnidades()
						dispatch(openSnack({ texto: 'Unidad actualizada con éxito', tipo: 'success' }))
					} else dispatch(openSnack({ texto: 'Error al actualizar los datos de la unidad', tipo: 'error' }))
					dispatch(endLoading())
				})
		} else {
			setState(ps => ({ ...ps, unidadError: true }))
			dispatch(openSnack({ texto: 'Uno o mas campos obligatorios están vacíos', tipo: 'info' }))
		}
	}

	const onDeleteUnidad = unidad => {
		dispatch(startLoading())
		apiDelete(urlUnidades, { unidadId: unidad.id }).then(r => {
			if (r.status === 'OK') {
				getUnidades()
				handleClose()
				dispatch(openSnack({ texto: 'Unidad eliminada con éxito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al eliminar unidad', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const handleClose = () => {
		setState(ps => ({
			...ps,
			openNew: false,
			unidadActual: false,
			edit: false,
			nombre: '',
			descripcion: '',
			padreId: -1,
			openDelete: false,
			unidadError: false
		}))
	}

	const unidadActiva = idPadre => {
		const unidad = state.unidades.filter(u => u.id === idPadre)
		return unidad.length ? unidad[0].completado : true
	}

	const guardarIndice = indices => {
		apiPut(urlUnidades, { indices, bulk: true }).then(r => {
			if (r.status !== 'OK')
				dispatch(
					openSnack({
						texto: 'Puede que algunos cambios en el indice no se hayan guardado correctamente',
						tipo: 'warning'
					})
				)
		})
	}

	//DRAG AND DROP METHODS

	const reorder = (list, startIndex, endIndex) => {
		var result = Array.from(list)
		const [ removed ] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		const guardar = result.map((e, i) => ({ id: e.id, indice: i }))
		guardarIndice(guardar)
		return result
	}

	const onDragEnd = result => {
		// dropped outside the list
		if (!result.destination) {
			return
		}
		const unidades = reorder(state.unidades, result.source.index, result.destination.index)
		setState(ps => ({
			...ps,
			unidades
		}))
	}

	const {
		nombre,
		openNew,
		descripcion,
		padreId,
		unidades,
		unidadActual,
		edit,
		openDelete,
		unidadError,
		anchorEl
	} = state
	return (
		<div className={classes.root}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Link to='/dashboard/miscursos'>
					<IconButton>
						<ArrowBack />
					</IconButton>
				</Link>
				<Typography variant='h6' color='primary'>
					{curso.tematica ? 'Curso de ' + curso.tematicaNombre + ': ' + curso.nombre : 'Cargando...'}
				</Typography>
			</div>
			{hasPermission(rol, infoPermisos, permisos.EDITAR_UNIDAD) ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId='droppable'>
						{(provided, snapshot) => (
							<List className={classes.list} {...provided.droppableProps} ref={provided.innerRef}>
								{unidades.map((u, i) => (
									<Draggable key={u.id} draggableId={`${u.id}`} index={i}>
										{(provided, snapshot) => (
											<Card
												key={'crd-' + i}
												className={classes.listItem}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													color: snapshot.isDragging ? 'white' : 'inherit',
													backgroundColor: snapshot.isDragging
														? theme.palette.primary.main
														: theme.palette.background.paper,
													...provided.draggableProps.style
												}}
											>
												<ListItem
													key={'li21-' + i}
													disabled={
														!unidadActiva(u.obligatoria_previa) &&
														!hasPermission(rol, infoPermisos, permisos.CREAR_MODULO)
													}
													button
													alignItems='flex-start'
													onClick={() =>
														setRedirect(u, window.location.pathname + '/unidad/' + u.id)}
												>
													<ListItemAvatar
														key={'lia32-' + i}
														style={{
															marginRight: 10,
															marginTop: 0,
															alignSelf: 'center'
														}}
													>
														<Avatar
															key={'av6546-' + i}
															style={{
																color: 'white',
																backgroundColor: u.progreso
																	? getProgressColor(u.progreso)
																	: 'grey',
																width: 50,
																height: 50
															}}
														>
															{Math.round(u.progreso) >= 100 ? (
																<Done />
															) : Math.round(u.progreso) < 0 ? u.nombre.length ? (
																// u.nombre[0]
																'U'
															) : (
																'U'
															) : (
																`${Math.round(u.progreso)}%`
															)}
														</Avatar>
													</ListItemAvatar>
													<ListItemText
														key={'lity745-' + i}
														primary={`Unidad: ${u.nombre}`}
														secondary={
															<React.Fragment>
																<Typography
																	style={{ display: 'block' }}
																	component='span'
																	variant='body2'
																	color='textPrimary'
																>
																	Actividades: {u.cantActividades}{' '}
																</Typography>
																{u.descripcion}
															</React.Fragment>
														}
													/>
													<ListItemSecondaryAction>
														{hasPermission(rol, infoPermisos, permisos.EDITAR_UNIDAD) ||
														hasPermission(rol, infoPermisos, permisos.ELIMINAR_UNIDAD) ? (
															<IconButton
																edge='end'
																aria-label='options'
																onClick={e =>
																	setState(ps => ({
																		...ps,
																		anchorEl: e.currentTarget,
																		unidadActual: u
																	}))}
															>
																<MoreVert />
															</IconButton>
														) : null}
													</ListItemSecondaryAction>
												</ListItem>
												<Menu
													key={'menu-' + i}
													anchorEl={anchorEl}
													keepMounted
													open={Boolean(anchorEl) && unidadActual.id === u.id}
													onClose={() =>
														setState(ps => ({
															...ps,
															anchorEl: null,
															unidadActual: {}
														}))}
												>
													{hasPermission(rol, infoPermisos, permisos.EDITAR_UNIDAD) ? (
														<MenuItem
															key={'menui-2' + i}
															onClick={() =>
																setState(ps => ({
																	...ps,
																	openNew: true,
																	edit: true,
																	unidadActual: u,
																	nombre: u.nombre,
																	descripcion: u.descripcion,
																	padreId: u.obligatoria_previa || -1
																}))}
														>
															<Edit color='primary' />
															<Typography className={classes.menuItemText}>
																Editar
															</Typography>
														</MenuItem>
													) : null}
													{hasPermission(rol, infoPermisos, permisos.ELIMINAR_UNIDAD) ? (
														<MenuItem
															key={'menui3-' + i}
															onClick={() =>
																setState(ps => ({
																	...ps,
																	openDelete: true,
																	unidadActual: u
																}))}
														>
															<Delete color='secondary' />
															<Typography className={classes.menuItemText}>
																Eliminar
															</Typography>
														</MenuItem>
													) : null}
												</Menu>
											</Card>
										)}
									</Draggable>
								))}
							</List>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<List className={classes.list}>
					{unidades.map((u, i) => (
						<Tooltip
							open={u.obligatoria_previa ? undefined : false}
							classes={{ tooltip: classes.editTooltip, arrow: classes.editTooltipArrow }}
							placement='top'
							title={'Correlativa: ' + u.obligatoria_nombre}
							arrow
						>
							<Card key={'crd-' + i} className={classes.listItem}>
								<ListItem
									key={'li21-' + i}
									disabled={
										!unidadActiva(u.obligatoria_previa) &&
										!hasPermission(rol, infoPermisos, permisos.CREAR_MODULO)
									}
									button
									alignItems='flex-start'
									onClick={() => setRedirect(u, window.location.pathname + '/unidad/' + u.id)}
								>
									<ListItemAvatar
										key={'lia32-' + i}
										style={{ marginRight: 10, marginTop: 0, alignSelf: 'center' }}
									>
										<Avatar
											key={'av6546-' + i}
											style={{
												color: 'white',
												backgroundColor: u.progreso ? getProgressColor(u.progreso) : 'grey',
												width: 50,
												height: 50
											}}
										>
											{Math.round(u.progreso) >= 100 ? (
												<Done />
											) : Math.round(u.progreso) < 0 ? u.nombre.length ? (
												// u.nombre[0]
												'U'
											) : (
												'U'
											) : (
												`${Math.round(u.progreso)}%`
											)}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										key={'lity745-' + i}
										primary={`Unidad: ${u.nombre}`}
										secondary={
											<React.Fragment>
												<Typography
													style={{ display: 'block' }}
													component='span'
													variant='body2'
													color='textPrimary'
												>
													Actividades: {u.cantActividades}{' '}
												</Typography>
												{u.descripcion}
											</React.Fragment>
										}
									/>
									<ListItemSecondaryAction>
										{hasPermission(rol, infoPermisos, permisos.EDITAR_UNIDAD) ||
										hasPermission(rol, infoPermisos, permisos.ELIMINAR_UNIDAD) ? (
											<IconButton
												edge='end'
												aria-label='options'
												onClick={e =>
													setState(ps => ({
														...ps,
														anchorEl: e.currentTarget,
														unidadActual: u
													}))}
											>
												<MoreVert />
											</IconButton>
										) : null}
									</ListItemSecondaryAction>
								</ListItem>
								<Menu
									key={'menu-' + i}
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl) && unidadActual.id === u.id}
									onClose={() => setState(ps => ({ ...ps, anchorEl: null, unidadActual: {} }))}
								>
									{hasPermission(rol, infoPermisos, permisos.EDITAR_UNIDAD) ? (
										<MenuItem
											key={'menui-2' + i}
											onClick={() =>
												setState(ps => ({
													...ps,
													openNew: true,
													edit: true,
													unidadActual: u,
													nombre: u.nombre,
													descripcion: u.descripcion,
													padreId: u.obligatoria_previa || -1
												}))}
										>
											<Edit color='primary' />
											<Typography className={classes.menuItemText}>Editar</Typography>
										</MenuItem>
									) : null}
									{hasPermission(rol, infoPermisos, permisos.ELIMINAR_UNIDAD) ? (
										<MenuItem
											key={'menui3-' + i}
											onClick={() =>
												setState(ps => ({ ...ps, openDelete: true, unidadActual: u }))}
										>
											<Delete color='secondary' />
											<Typography className={classes.menuItemText}>Eliminar</Typography>
										</MenuItem>
									) : null}
								</Menu>
							</Card>
						</Tooltip>
					))}
				</List>
			)}
			{hasPermission(rol, infoPermisos, permisos.CREAR_UNIDAD) ? (
				<Fab
					className={classes.addButton}
					size='large'
					color='primary'
					onClick={() => setState(ps => ({ ...ps, openNew: true }))}
				>
					<Add />
				</Fab>
			) : null}
			<Dialog
				fullWidth={true}
				scroll='paper'
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth={'md'}
				open={openNew || openDelete}
			>
				<DialogTitle disableTypography>
					<Typography variant='h6'>
						{edit ? (
							'Editar unidad: ' + unidadActual.nombre
						) : openDelete ? (
							'Eliminar unidad: ' + unidadActual.nombre
						) : (
							'Nueva unidad'
						)}
					</Typography>
					<IconButton aria-label='close' className={classes.closeButton} onClick={() => handleClose()}>
						<Close color='error' />
					</IconButton>
				</DialogTitle>
				<DialogContent className={classes.dialogContent} dividers={true}>
					{openNew ? (
						<React.Fragment>
							<TextField
								className={classes.input}
								name='nombre'
								value={nombre}
								variant='filled'
								onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
								label='Nombre'
								required
								error={unidadError && !nombre}
							/>
							<TextField
								className={classes.input}
								name='descripcion'
								value={descripcion}
								variant='filled'
								onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
								label='Descripcion'
								multiline
								rows={2}
								rowsMax={6}
							/>
							<TextField
								className={classes.input}
								name='padreId'
								value={padreId}
								variant='filled'
								onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
								label='Unidad correlativa'
								select
							>
								{[ { id: -1, nombre: 'Sin correlativa' }, ...unidades ].map(
									(v, i) =>
										edit && v.id === unidadActual.id ? null : (
											<MenuItem key={v.id} value={v.id}>
												{v.nombre}
											</MenuItem>
										)
								)}
							</TextField>
						</React.Fragment>
					) : (
						<Typography>¿Está seguro que desea eliminar esta unidad?</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						color={openDelete ? 'secondary' : 'primary'}
						variant='text'
						onClick={() => (openDelete ? onDeleteUnidad(unidadActual) : onCrearUnidad())}
					>
						{openDelete ? 'Eliminar' : edit ? 'Modificar' : 'Guardar'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
