import React, { useState } from 'react'
import { Typography, Box, TablePagination, makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles(theme => ({
	box: {
		//margin: 10,
		width: '100%',
		height: '100%'
		//overflowY: 'scroll'
	},
	table: {
		minWidth: 600,
		width: '100%'
	},
	header: {
		backgroundColor: theme.palette.primary.main
	},
	headCell: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white
	},
	footer: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		width: '100%',
		height: 53
	}
}))

const useStyles1 = makeStyles(theme => ({
	root: {
		flexShrink: 0
	},
	button: {
		color: 'white'
	},
	total: {}
}))

function TablePaginationActions(props) {
	const classes = useStyles1()

	const { count, page, rowsPerPage, onChangePage } = props

	const handleFirstPageButtonClick = event => {
		onChangePage(event, 0)
	}

	const handleBackButtonClick = event => {
		onChangePage(event, page - 1)
	}

	const handleNextButtonClick = event => {
		onChangePage(event, page + 1)
	}

	const handleLastPageButtonClick = event => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<div className={classes.root}>
			<IconButton
				className={classes.button}
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label='first page'
			>
				<FirstPageIcon />
			</IconButton>
			<IconButton
				className={classes.button}
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
			>
				<KeyboardArrowLeft />
			</IconButton>
			<Typography variant='inherit'>{page + 1}</Typography>
			<IconButton
				className={classes.button}
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'
			>
				<KeyboardArrowRight />
			</IconButton>
			<IconButton
				className={classes.button}
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='last page'
			>
				<LastPageIcon />
			</IconButton>
		</div>
	)
}

export default function GenericTable(props) {
	const [ { rowsPerPage, pageBug }, setState ] = useState({ rowsPerPage: 20, pageBug: 0 })
	const classes = useStyles()

	const handleChangePage = (event, newPage) => {
		setState({ pageBug: newPage })
	}

	const handleChangeRowsPerPage = event => {
		setState({ rowsPerPage: parseInt(event.target.value, 10) })
		setState({ pageBug: 0 })
	}

	const { data, columns, onCellClick } = props
	let page = data.length / rowsPerPage <= pageBug ? 0 : pageBug
	const rows = rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data
	return (
		<Box className={classes.box}>
			<TableContainer style={{ height: 'calc(100% - 53px)' }}>
				<Table stickyHeader className={classes.table} aria-label='simple table'>
					<TableHead className={classes.header}>
						<TableRow>
							{columns.map((c, i) => (
								<TableCell key={'column' + i} className={classes.headCell}>
									{c.label[0].toUpperCase() + c.label.substring(1, c.length)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => (
							<TableRow
								onClick={() => onCellClick(row)}
								key={`${row.name}-${i}`}
								style={{ cursor: 'pointer', backgroundColor: i % 2 === 0 ? 'lightgrey' : 'white' }}
							>
								{columns.map((c, j) => (
									<TableCell key={'tablecell' + j + '-' + i} scope='row'>
										{row[c.prop]}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				className={classes.footer}
				component={Box}
				rowsPerPageOptions={[ 20, 35, 50 ]}
				colSpan={3}
				labelRowsPerPage='Filas por página: '
				count={data.length}
				labelDisplayedRows={(pagInfo, label) => {
					const { from, to, count } = pagInfo
					return `${from} - ${to} de ${count}`
				}}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
				color='primary'
			/>
		</Box>
	)
}
