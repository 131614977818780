import React, { useEffect, useState } from 'react'
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	TextField,
	Tooltip,
	Typography
} from '@material-ui/core'
import { apiGetWithParams, apiPut } from '../../../api'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { urlUsuarios } from '../../../api/urls'
import { useDispatch, useSelector } from 'react-redux'
import { grey } from '@material-ui/core/colors'
import { validateSize } from '../../../constants'
import { ReplayRounded } from '@material-ui/icons'
import { encodeBase64 } from '../../../api/encoding'

const tamIcono = 1

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	image: {
		width: 150,
		height: 150,
		/* los siguientes valores son independientes del tamaño del círculo */
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50%',
		borderRadius: '50%',
		backgroundSize: '100% auto',
		border: '1px solid ' + grey['300']
	},
	editTooltip: {
		backgroundColor: 'white',
		color: 'black',
		maxWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid ' + theme.palette.primary.main
	},
	editTooltipArrow: {
		color: theme.palette.primary.main
	}
}))

export default function EditarPerfilDialog(props) {
	const { open, onClose, reload } = props
	const classes = useStyles()
	const [ userInfo, setUserInfo ] = useState({})
	const [
		{ first_name, last_name, fecha_nacimiento, icono, iconoModificado, dni, email, error, username },
		setEditing
	] = useState({
		first_name: '',
		last_name: '',
		fecha_nacimiento: '',
		icono: '',
		iconoModificado: '',
		dni: '',
		email: '',
		error: false
	})
	const defaultImagePlaceholder = useSelector(state => state.metadata.imagePlaceholder)
	const [ imagenPreview, setImagenPreview ] = useState(defaultImagePlaceholder)
	const id = useSelector(state => state.login.id)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(startLoading())
		apiGetWithParams(urlUsuarios, { id })
			.then(r => {
				if (r.status === 'OK') {
					setUserInfo(r.data)
					setEditing(r.data)
					if (r.data.icono) setImagenPreview(r.data.icono)
				} else dispatch(openSnack({ texto: 'Error al obtener los datos del perfil', tipo: 'error' }))
				dispatch(endLoading())
			})
			.then(e => console.log(e))
	}, [])

	const onSave = () => {
		if (first_name && last_name && fecha_nacimiento && email && dni) {
			const doPut = e =>{
                dispatch(startLoading())
				apiPut(urlUsuarios, {
                    id,
					first_name,
					last_name,
					fecha_nacimiento,
					dni,
					email,
					icono: e ? e.target.result : '',
                    editarIcono: iconoModificado,
                    username
				}).then(r => {
                    dispatch(endLoading())
                    if (r.status === 'OK') {
                        reload()
                        dispatch(openSnack({texto: 'Perfil editado con éxito', tipo: 'success'}))
                        onClose()
                    } else dispatch(openSnack({texto: 'Error al editar perfil', tipo: 'error'}))
                })
            }
			if (iconoModificado) encodeBase64(icono, doPut)
			else doPut()
		} else {
			dispatch(openSnack({ texto: 'Falta completar uno o mas campos obligatorios', tipo: 'info' }))
			setEditing(ps => ({ ...ps, error: true }))
		}
	}

	const renderTF = (label, name, value) => (
		<TextField
			margin='dense'
			variant='filled'
			name={name}
			label={label}
			value={value}
			fullWidth
			required
			error={error && !value}
			onChange={({ target: { name, value } }) => setEditing(ps => ({ ...ps, [name]: value }))}
		/>
	)

	const deshacerIcono = () => {
		setEditing(ps => ({ ...ps, iconoModificado: false, icono: userInfo.icono }))
		setImagenPreview(userInfo.icono)
	}

	return (
		<Dialog open={open} fullWidth maxWidth='sm'>
			<DialogTitle>Editar perfil</DialogTitle>
			<DialogContent className={classes.content} dividers>
				<Box display='flex' flexDirection='column' alignItems='center'>
					<Button
						className={classes.image}
						style={{ backgroundImage: `url(${imagenPreview})` }}
						onClick={() => document.getElementById('path2').click()}
					>
						<input
							accept='image/*'
							style={{ display: 'none' }}
							id='path2'
							type='file'
							onChange={({ target: { files } }) => {
								if (files[0]) {
									if (validateSize(files[0], tamIcono * 1024 * 1024)) {
										setEditing(ps => ({ ...ps, icono: files[0], iconoModificado: true }))
										setImagenPreview(URL.createObjectURL(files[0]))
									} else
										dispatch(
											openSnack({
												texto: 'El tamaño de la imagen no pude superar ' + tamIcono + 'mb',
												tipo: 'info'
											})
										)
								}
							}}
						/>
					</Button>
				</Box>
				<Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
					<Typography align='center'>{icono ? icono.name : 'Seleccione una imagen'}</Typography>
					{iconoModificado ? (
						<Tooltip
							classes={{ tooltip: classes.editTooltip, arrow: classes.editTooltipArrow }}
							placement='top'
							title={'Deshacer cambiar icono'}
							arrow
						>
							<IconButton onClick={deshacerIcono} color='secondary'>
								<ReplayRounded />
							</IconButton>
						</Tooltip>
					) : null}
				</Box>
				{renderTF('Nombre', 'first_name', first_name)}
				{renderTF('Apellido', 'last_name', last_name)}
				{renderTF('DNI', 'dni', dni)}
				{renderTF('Mail', 'email', email)}
				{renderTF('Fecha de nacimiento', 'fecha_nacimiento', fecha_nacimiento)}
			</DialogContent>
			<DialogActions>
				<Button color='secondary' onClick={onClose}>
					Salir
				</Button>
				<Button color='primary' variant='contained' onClick={onSave}>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
