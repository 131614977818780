import React, { Fragment, useState, useEffect } from 'react'
import { Card, TextField, MenuItem, Button, Typography, DialogContent, DialogTitle, DialogActions, Dialog, Fab, Divider, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Add } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import { apiGet, apiPost, apiGetWithParams, apiPut, apiDelete } from '../../../api'
import { urlRoles, urlUsuarios } from '../../../api/urls'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { openSnack } from '../../../actions/feedback/openSnack'
import { hasPermission, permisos } from '../../../constants/permisos'
import GenericTable from '../../GenericTable'
import { format } from 'date-fns'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%' 
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		padding: 20
	},
	input: {
		marginBottom: 10
	},
	table: {
		height: '100%',
		width: '100%'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2) + 54 + 24,
		right: theme.spacing(2) + 24
	}
}))

export default function Usuarios() {
	const classes = useStyles()
	const [state, setState] = useState({
		username: '',
		contrasenia: '',
		contrasenia2: '',
		first_name: '',
		last_name: '',
		email: '',
		tipouser: '',
		dni: '',
		fecha_nacimiento: new Date(),
		roles: [],
		usuarioError: false,
		data: [],
		open: false,
		edit: false,
		del: false,
		ver: false
	})
	const infoPermisos = useSelector(state => state.permisos)
	const rol = useSelector(state => state.login.rol)

	const dispatch = useDispatch()

	const getUsers = params => {
		apiGetWithParams(urlUsuarios, params || {}).then(r => {
			if (r.status === 'OK') setState(ps => ({...ps, data: r.data }))
			else dispatch(openSnack({ texto: 'Error al obtener la lista de usuarios', tipo: 'error' }))
		})
	}

	useEffect(() => {
		dispatch(startLoading())
		apiGet(urlRoles).then(r => {
			if (r.status === 'OK') setState(ps => ({...ps, roles: r.data }))
			else dispatch(openSnack({ texto: 'Error al obtener la lista de roles', tipo: 'Error' }))
			dispatch(endLoading())
		})
		getUsers({})
		// eslint-disable-next-line
	}, [dispatch, setState])

	const onCrearUsuario = () => {
		const { username, first_name, last_name, contrasenia, email, tipouser, dni, fecha_nacimiento, contrasenia2, edit, id } = state
		if ((username && first_name && last_name && email && dni && fecha_nacimiento && tipouser))
			if (contrasenia === contrasenia2) {
				dispatch(startLoading())
				const api = edit? apiPut : apiPost
				api(urlUsuarios, {
					id,
					username,
					first_name,
					last_name,
					contrasenia,
					email,
					tipouser,
					dni,
					fecha_nacimiento: format(fecha_nacimiento, 'dd-MM-yyyy')
				}).then(r => {
					if (r.status === 'OK') {
						getUsers()
						cleanForm()
						dispatch(openSnack({ texto: 'Usuario guardado con éxito', tipo: 'success' }))
					} else if (r.status === 'ALREADY_EXISTS') dispatch(openSnack({ texto: 'Ya existe un usuario con ese DNI', tipo: 'warning' }))
					else dispatch(openSnack({ texto: 'Error al guardar usuario', tipo: 'error' }))
					dispatch(endLoading())
				})
			} else dispatch(openSnack({ texto: 'Las contraseñas no coinciden', tipo: 'info' }))
		else {
			dispatch(openSnack({ texto: 'Falta completar uno o mas campos obligatorios', tipo: 'info' }))
			setState(ps => ({ ...ps, usuarioError: true }))
		}
	}

	const eliminar = () => {
		apiDelete(urlUsuarios, {id: state.id}).then(r => {
			if (r.status === 'OK'){
				getUsers()
				cleanForm()
				dispatch(openSnack({texto: 'Usuario eliminado con éxito', tipo: 'success'}))
			} else dispatch(openSnack({texto: 'Error al eliminar usuario', tipo: 'error'}))
		})
	}

	const cleanForm = () => {
		setState({
			...state,
			username: '',
			contrasenia: '',
			contrasenia2: '',
			first_name: '',
			last_name: '',
			email: '',
			tipouser: '',
			dni: '',
			id: undefined,
			nombreCompleto: '',
			fecha_nacimiento: new Date(),
			usuarioError: false,
			open: false,
			edit: false,
			del: false,
			ver: false
		})
	}

	const parseDate = fecha => {
		if (!fecha) return new Date()
		const [y, m, d] = fecha.split('-')
		return new Date(y, m-1, d)
	}

	const {
		data,
		username,
		contrasenia,
		contrasenia2,
		first_name,
		last_name,
		email,
		tipouser,
		dni,
		fecha_nacimiento,
		roles,
		usuarioError,
		open,
		edit,
		del,
		ver,
		nombreCompleto
	} = state
	return (
		<div className={classes.root}>
			{hasPermission(rol, infoPermisos, permisos.CREAR_USUARIO) ? (
				<Dialog open={open || edit || del || ver} fullWidth maxWidth='sm'>
					<DialogTitle>{open ? 'Nuevo' : edit ? 'Editar' : del ? 'Eliminar' : 'Datos de'} usuario</DialogTitle>
					<DialogContent className={classes.card}>
						{ver ? (
							<Fragment>
								<Typography>Nombre de usuario: {username}</Typography>
								<Typography>Nombre: {nombreCompleto}</Typography>
								<Typography>DNI: {dni}</Typography>
								<Typography>Mail: {email}</Typography>
								<Typography>Fecha de nacimineto: {format(fecha_nacimiento, 'dd/MM/yyyy')}</Typography>
								<Typography>Rol: {rol}</Typography>
							</Fragment>
						) : del ? (
							<Typography>¿Está seguro que desea eliminar a {nombreCompleto}?</Typography>
						) : (
							<Fragment>
								<TextField
									className={classes.input}
									name='username'
									label='Nombre de usuario'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={username}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !username}
								/>
								<TextField
									className={classes.input}
									name='first_name'
									label='Nombre'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={first_name}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !first_name}
								/>
								<TextField
									className={classes.input}
									name='last_name'
									label='Apellido'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={last_name}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !last_name}
								/>
								<TextField
									className={classes.input}
									name='email'
									label='Mail'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={email}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !email}
								/>
								<TextField
									className={classes.input}
									name='dni'
									label='DNI'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={dni}
									variant='filled'
									type='number'
									required
									fullWidth
									error={usuarioError && !dni}
								/>
								<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
									<DatePicker
										className={classes.input}
										inputVariant='filled'
										label='Fecha de nacimiento'
										format='dd/MM/yyyy'
										value={fecha_nacimiento}
										maxDate={new Date()}
										onChange={e => setState(ps => ({...ps, fecha_nacimiento: e }))}
										cancelLabel='Atrás'
										okLabel='Seleccionar'
										color='primary'
										fullWidth
										invalidDateMessage='Seleccione una fecha valida'
									/>
								</MuiPickersUtilsProvider>
								<TextField
									className={classes.input}
									name='tipouser'
									label='ROL'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={tipouser}
									variant='filled'
									select={true}
									required
									fullWidth
									error={usuarioError && !tipouser}
								>
									{roles.map((r, i) => (
										<MenuItem key={i} value={r.acronimo}>
											{r.nombre}
										</MenuItem>
									))}
								</TextField>
								{edit? <Divider /> : null}
								{edit? <Typography paragraph>Cambiar contraseña (No completar para mantener la contraseña actual)</Typography> : null}
								<TextField
									className={classes.input}
									name='contrasenia'
									label='Contraseña'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={contrasenia}
									variant='filled'
									type='password'
									required={!edit}
									fullWidth
									error={usuarioError && !contrasenia}
								/>
								<TextField
									className={classes.input}
									name='contrasenia2'
									label='Confirmar contraseña'
									onChange={({target: { name, value } } ) => setState(ps => ({...ps, [name]: value }))}
									value={contrasenia2}
									variant='filled'
									type='password'
									required={!edit}
									fullWidth
									error={usuarioError && !contrasenia2}
								/>
							</Fragment>
						)}
					</DialogContent>
					<DialogActions>
						<Button variant='text' onClick={() => cleanForm()}>
							Cancelar
						</Button>
						{ver || del ? (
							<Button variant='outlined' color='secondary' onClick={() => (del ? eliminar() : setState(ps => ({ ...ps, del: true, ver: false, edit: false })))}>
								Eliminar
							</Button>
						) : null}
						{ver ? (
							<Button variant='contained' color='primary' onClick={() => setState(ps => ({...ps, edit: true, del: false, ver: false }))}>
								Editar
							</Button>
						) : null}
						{open || edit ? (
							<Button className={classes.guardarButton} variant='contained' onClick={onCrearUsuario} color='primary'>
								Guardar
							</Button>
						) : null}
					</DialogActions>
				</Dialog>
			) : null}

			<Card className={classes.table}>
				<GenericTable
					columns={[
						{ label: 'Nombre de usuario', prop: 'username' },
						{ label: 'Nombre', prop: 'first_name' },
						{ label: 'Apellido', prop: 'last_name' },
						{ label: 'DNI', prop: 'dni' },
						{ label: 'Rol', prop: 'rol' },
						{ label: 'Fecha de nacimiento', prop: 'fecha_nacimiento' },
						{ label: 'Mail', prop: 'email' }
					]}
					data={data}
					onCellClick={e => setState(ps => ({...ps, ver: true, ...e, tipouser: e.rolAcronimo, fecha_nacimiento: parseDate(e.fecha_nacimiento) }))}
				/>
			</Card>
			<Fab className={classes.fab} color='primary' onClick={() => setState(ps => ({...ps, open: true }))}>
				<Add />
			</Fab>
		</div>
	)
}