import React from 'react'
import {
	Button,
	Card,
	Checkbox,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	TextField,
	Typography,
	withStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { onChangeRespuestaModulo } from '../../../../../actions/modulos/onChangeRespuestaModulo'
import { useDispatch, useSelector } from 'react-redux'
import { green } from '@material-ui/core/colors'
import { hasPermission, permisos } from '../../../../../constants/permisos'

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		'&$checked': {
			color: green[600]
		}
	},
	checked: {}
})(props => <Checkbox color='default' {...props} />)

const getArrayBySize = size => {
	var arr = []
	for (var i = 0; i < size; i++) arr.push(0)
	return arr
}

export function MultipleChoicePrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props

	const checkBoxChange = name => {
		var opcionCorrecta = [ ...principal.opcionCorrecta ]
		const idx = opcionCorrecta.indexOf(name)
		if (idx !== -1) opcionCorrecta.splice(idx, 1)
		else opcionCorrecta.push(name)
		dispatch(
			onChangeModulo({
				name: 'formulario',
				value: { ...formulario, principal: { ...principal, opcionCorrecta } }
			})
		)
	}

	return (
		<React.Fragment>
			<TextField
				className={classes.input}
				name='pregunta'
				value={principal.pregunta}
				variant='filled'
				onChange={({ target: { name, value } }) =>
					dispatch(
						onChangeModulo({
							name: 'formulario',
							value: { ...formulario, principal: { ...principal, [name]: value } }
						})
					)}
				label='Pregunta'
				multiline
				rowsMax={5}
				rows={2}
				required
				error={moduloError && !principal.pregunta}
			/>

			<TextField
				className={classes.input}
				name='cantidad'
				value={principal.cantidad || ''}
				variant='filled'
				onChange={({ target: { name, value } }) =>
					dispatch(
						onChangeModulo({
							name: 'formulario',
							value: { ...formulario, principal: { ...principal, [name]: value, opcionCorrecta: '' } }
						})
					)}
				label='Cantidad de opciones'
				select
				required
				error={moduloError && !principal.cantidad}
			>
				{[ 2, 3, 4, 5 ].map(v => (
					<MenuItem key={v} value={v}>
						{v}
					</MenuItem>
				))}
			</TextField>
			{getArrayBySize(principal.cantidad || 0).map((_, i) => (
				<TextField
					className={classes.input}
					key={i}
					name={'opcion' + i || ''}
					value={principal['opcion' + i]}
					variant='filled'
					onChange={({ target: { value, name } }) =>
						dispatch(
							onChangeModulo({
								name: 'formulario',
								value: { ...formulario, principal: { ...principal, [name]: value } }
							})
						)}
					label={'Opcion ' + (i + 1)}
					multiline
					rowsMax={3}
					rows={1}
					required
					error={moduloError && !principal['opcion' + i]}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<GreenCheckbox
									name={'check' + i}
									checked={principal.opcionCorrecta.indexOf('opcion' + i) !== -1}
									onChange={_ => checkBoxChange('opcion' + i)}
									inputProps={{ 'aria-label': 'primary checkbox' }}
									className={classes.check}
								/>
							</InputAdornment>
						)
					}}
				/>
			))}
		</React.Fragment>
	)
}

export function MultipleChoiceRender(props) {
	const { modulo, classes, guardarRespuesta } = props
	const rol = useSelector(state => state.login.rol)
	const data = useSelector(state => state.modulos.data[modulo.id] || {})
	const infoPermisos = useSelector(state => state.permisos)
	const responderPermiso = hasPermission(rol, infoPermisos, permisos.RESPONDER_MODULOS)
	const dispatch = useDispatch()

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography>{modulo.principal.pregunta}</Typography>
			<List>
				{getArrayBySize(modulo.principal.cantidad).map((_, i) => (
					<Card
						key={'card-' + i}
						className={
							modulo.respuesta ? (
								clsx(
									{
										[classes.item]:
											!modulo.respuesta.respuesta ||
											-1 === modulo.respuesta.respuesta.indexOf('opcion' + i)
									},
									{
										[classes.itemSeleccionado]:
											modulo.respuesta.respuesta &&
											-1 !== modulo.respuesta.respuesta.indexOf('opcion' + i)
									},
									{
										[classes.itemCorrecto]:
											modulo.principal.opcionCorrecta &&
											modulo.principal.opcionCorrecta.indexOf('opcion' + i) !== -1
									},
									{
										[classes.itemIncorrecto]:
											modulo.respuesta.respuesta.indexOf('opcion' + i) !== -1 &&
											modulo.principal.opcionCorrecta &&
											modulo.principal.opcionCorrecta.indexOf('opcion' + i) === -1
									}
								)
							) : (
								clsx(
									{ [classes.item]: !data.respuesta || -1 === data.respuesta.indexOf('opcion' + i) },
									{
										[classes.itemSeleccionado]:
											data.respuesta && -1 !== data.respuesta.indexOf('opcion' + i)
									}
								)
							)
						}
					>
						<ListItem
							button={modulo.respuesta ? false : true}
							key={'lik-' + i}
							onClick={() => {
								if (modulo.respuesta === null) {
									var res = data.respuesta ? [ ...data.respuesta ] : []
									const idx = res.indexOf('opcion' + i)
									if (idx === -1) res.push('opcion' + i)
									else res.splice(idx, 1)
									dispatch(
										onChangeRespuestaModulo({ name: modulo.id, value: { ...data, respuesta: res } })
									)
								}
							}}
						>
							<ListItemText key={'litkl-' + i}>{modulo.principal['opcion' + i]}</ListItemText>
						</ListItem>
					</Card>
				))}
			</List>
			<Button
				disabled={modulo.respuesta ? true : responderPermiso ? false : true}
				style={{ marginBottom: 10, alignSelf: 'flex-end' }}
				color='primary'
				variant='contained'
				onClick={() => guardarRespuesta({ moduloId: modulo.id, data: { respuesta: data.respuesta } })}
			>
				Responder
			</Button>
		</div>
	)
}

export const multipleChoiceValidar = principal => {
	var res = true && principal.pregunta
	res = res && principal.opcionCorrecta && principal.opcionCorrecta.length > 0
	if (!res) return res
	else for (let i = 0; i < principal.cantidad; i++) res = res && principal['opcion' + i]
	return res
}
