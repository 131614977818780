import React, { useState } from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { Link } from 'react-router-dom'
import {
	GroupRounded,
	HowToRegRounded,
    NightsStayRounded,
	PersonRounded,
	PowerSettingsNewRounded,
	SchoolRounded,
	VpnKeyRounded,
    WbSunnyRounded
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from '../../../actions/login/logout'
import Perfil from '../Perfil'
import EditarPerfilDialog from '../Perfil/EditarPerfilDialog'
import { Avatar, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { hasPermission, permisos } from '../../../constants/permisos'
import { apiPost } from '../../../api'
import { urlLogin } from '../../../api/urls'
import { login } from '../../../actions/login/login'
import { toggleDarkMode } from '../../../actions/theme/toggleDarkMode'
import CambiarContrasenia from '../Perfil/CambiarContrasenia'
import { indigo, purple, yellow } from '@material-ui/core/colors'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderBottom: theme.palette.type === 'dark'? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)',
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
        })
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	hide: {
		display: 'none'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap'
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		overflowX: 'hidden'
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: 56,
		[theme.breakpoints.up('sm')]: {
			width: 56
		}
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	}
}))

const menuCursosTodos = { text: 'Cursos', icon: <SchoolRounded />, link: '/dashboard/cursos' }
const menuCursos = { text: 'Mis Cursos', icon: <HowToRegRounded />, link: '/dashboard/miscursos' }
const menuUsuarios = { text: 'Usuarios', icon: <GroupRounded />, link: '/dashboard/usuarios' }
//const menuNoticias = { text: 'Noticias', icon: faNewspaper, link: '/dashboard/noticias' }
//const menuAsignar = { text: 'Asignar a curso', icon: <PersonAddRounded />, link: '/dashboard/asignar' }
//const menuGrupos = { text: 'Grupos', icon: <GroupRounded />, link: '/dashboard/grupos' }
//const menuConfig = { text: 'Opciones', icon: <SettingsRounded />, link: '/dashboard/opciones' }

export default function MiniDrawer() {
	const [ { open }, setState ] = useState({ open: false })
	const [ menuOpen, setMenuOpen ] = useState(false)
	const [ perfilOpen, setPerfilOpen ] = useState(false)
	const [ passOpen, setPassOpen ] = useState(false)
	const [ editarPerfilOpen, setEditarPerfilOpen ] = useState(false)
	const classes = useStyles()
	const dispatch = useDispatch()
	const userData = useSelector(state => state.login)
	const infoPermisos = useSelector(state => state.permisos)
    const nombre = useSelector(state => state.metadata.nombre)
    const prefersDarkMode = useSelector(state => state.theme.prefersDarkMode)

	const handleDrawerOpen = () => {
		setState({ open: true })
	}

	const handleDrawerClose = () => {
		setState({ open: false })
	}

	const reload = () => {
		apiPost(urlLogin, { token: true })
			.then(r => {
				if (r.status === 'OK') dispatch(login(r))
			})
			.catch(e => console.log(e))
	}

	var menu1 = [ menuCursosTodos, menuCursos ]
	var menu2 = []
	//if (hasPermission(userData.rol, infoPermisos, permisos.ASIGNAR_USUARIO_CURSO)) menu2.push(menuAsignar)
	//if (hasPermission(userData.rol, infoPermisos, permisos.VER_GRUPOS)) menu2.push(menuGrupos)
	if (hasPermission(userData.rol, infoPermisos, permisos.VER_USUARIOS)) menu2.push(menuUsuarios)
	// menu2.push(menuConfig)
	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar
                position='fixed'
                color='default'
                elevation={0}
				className={clsx(
					classes.appBar,
					{
						// [classes.appBarShift]: open,
					}
				)}
			>
				<Toolbar style={{ justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							onClick={open ? handleDrawerClose : handleDrawerOpen}
							edge='start'
							className={clsx(
								classes.menuButton,
								{
									//[classes.hide]: open,
								}
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant='h6' noWrap>
							{nombre}
						</Typography>
					</div>
					<IconButton
						style={{ justifySelf: 'flex-end', color: 'white' }}
						onClick={e => setMenuOpen(e.currentTarget)}
					>
						{userData.icono ? (
							<Avatar alt='foto-perfil' src={userData.icono} />
						) : (
							<Avatar>
								<PersonRounded />
							</Avatar>
						)}
					</IconButton>
				</Toolbar>
				<Menu
					classes={{ paper: classes.menu }}
					anchorEl={menuOpen}
					open={Boolean(menuOpen)}
					onClose={() => setMenuOpen(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
				>
					<MenuItem
						onClick={() => {
							setPerfilOpen(true)
							setMenuOpen(null)
						}}
					>
						<ListItemIcon>
							<PersonRounded color='primary' fontSize='small' />
						</ListItemIcon>
						<Typography variant='inherit'>Perfil</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
                            setPassOpen(true)
							setMenuOpen(null)
						}}
					>
						<ListItemIcon>
							<VpnKeyRounded color='primary' fontSize='small' />
						</ListItemIcon>
						<Typography variant='inherit'>Cambiar contraseña</Typography>
					</MenuItem>
                    {/* <Divider />
					<MenuItem
						onClick={() => {
                            dispatch(toggleDarkMode())
						}}
					>
						<ListItemIcon>
							{prefersDarkMode? <WbSunnyRounded style={{color: yellow[200]}} fontSize='small' /> : <NightsStayRounded style={{color: indigo[500]}} fontSize='small' />}
						</ListItemIcon>
						<Typography variant='inherit'>{prefersDarkMode? 'Tema claro' : 'Tema oscuro'}</Typography>
					</MenuItem> */}
                    <Divider />
					<MenuItem
						onClick={() => {
							setMenuOpen(null)
							dispatch(logout())
						}}
					>
						<ListItemIcon>
							<PowerSettingsNewRounded color='secondary' fontSize='small' />
						</ListItemIcon>
						<Typography variant='inherit'>Cerrar sesión</Typography>
					</MenuItem>
				</Menu>
			</AppBar>
			{perfilOpen ? (
				<Perfil
					open={perfilOpen}
					onClose={() => setPerfilOpen(false)}
					onEdit={() => {
						setPerfilOpen(false)
						setEditarPerfilOpen(true)
					}}
				/>
			) : null}
			{editarPerfilOpen ? (
				<EditarPerfilDialog
					open={editarPerfilOpen}
					onClose={() => setEditarPerfilOpen(false)}
					reload={reload}
				/>
			) : null}
			{passOpen ? <CambiarContrasenia open={passOpen} onClose={() => setPassOpen(false)} /> : null}
			<Drawer
				variant='permanent'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open
					})
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{' '}
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					{menu1.map((op, i) => (
						<Link key={'l-' + i} to={op.link}>
							<ListItem key={'li-' + i} button>
								<ListItemIcon key={'lii-' + i}>{op.icon}</ListItemIcon>
								<ListItemText key={'lit-' + i} primary={op.text} />
							</ListItem>
						</Link>
					))}
				</List>
				<Divider />
				<List>
					{menu2.map((op, i) => (
						<Link key={'l-' + i} to={op.link}>
							<ListItem key={'li-' + i} button>
								<ListItemIcon key={'lii-' + i}>{op.icon}</ListItemIcon>
								<ListItemText key={'lit-' + i} primary={op.text} />
							</ListItem>
						</Link>
					))}
				</List>
			</Drawer>
		</React.Fragment>
	)
}
