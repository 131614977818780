export const permisos = {
    EDITAR_MODULO: 'EDITAR_MODULO',
    EDITAR_UNIDAD: 'EDITAR_UNIDAD',
    EDITAR_CURSO: 'EDITAR_CURSO',
    EDITAR_TEMATICA: 'EDITAR_TEMATICA',
    EDITAR_USUARIO_HIJO: 'EDITAR_USUARIO_HIJO',
    EDITAR_USUARIO: 'EDITAR_USUARIO',
    EDITAR_GRUPO: 'EDITAR_GRUPO',
    ASIGNAR_USUARIO_GRUPO: 'ASIGNAR_USUARIO_GRUPO',
    ASIGNAR_USUARIO_CURSO: 'ASIGNAR_USUARIO_CURSO',
    ALTERAR_INDICE_MODULO: 'ALTERAR_INDICE_MODULO',
    CREAR_MODULO: 'CREAR_MODULO',
    CREAR_UNIDAD: 'CREAR_UNIDAD',
    CREAR_CURSO: 'CREAR_CURSO',
    CREAR_TEMATICA: 'CREAR_TEMATICA',
    CREAR_USUARIO_HIJO: 'CREAR_USUARIO_HIJO',
    CREAR_USUARIO: 'CREAR_USUARIO',
    CREAR_GRUPO: 'CREAR_GRUPO',
    ELIMINAR_MODULO: 'ELIMINAR_MODULO',
    ELIMINAR_GRUPO: 'ELIMINAR_GRUPO',
    ELIMINAR_UNIDAD: 'ELIMINAR_UNIDAD',
    ELIMINAR_CURSO: 'ELIMINAR_CURSO',
    ELIMINAR_TEMATICA: 'ELIMINAR_TEMATICA',
    ELIMINAR_USUARIO_HIJO: 'ELIMINAR_USUARIO_HIJO',
    ELIMINAR_USUARIO: 'ELIMINAR_USUARIO',
    VER_GRUPOS: 'VER_GRUPOS',
    VER_USUARIOS_HIJOS: 'VER_USUARIOS_HIJOS',
    VER_USUARIOS: 'VER_USUARIOS',
    RESPONDER_MODULOS: 'RESPONDER_MODULOS',
    ADMIN: 'ADMIN',
}

export const hasPermission = (rol, lista, NOMBRE_PERMISO) => {
    return lista[NOMBRE_PERMISO] && (lista[NOMBRE_PERMISO].indexOf(rol) !== -1)
}