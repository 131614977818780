import React from 'react'
import { Button, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'
import { green, red } from '@material-ui/core/colors'
import { hasPermission, permisos } from '../../../../../constants/permisos'
import { isAlfaNum } from '../../../../../constants'
import { onChangeRespuestaModulo } from '../../../../../actions/modulos/onChangeRespuestaModulo'

export function CompletarFrasePrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<React.Fragment>
			{principal.siguiente ? (
				<React.Fragment>
					<div
						className={classes.item}
						style={{
							padding: 10,
							marginBottom: 3,
							borderTopLeftRadius: 4,
							borderTopRightRadius: 4,
							minHeight: 30,
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							borderColor: moduloError && !principal.aCompletar.length ? red[500] : 'inherit'
						}}
					>
						{principal.textoCompleto.map((p, i) => (
							<Typography
								key={'asdasd-' + i}
								style={
									principal.aCompletar.indexOf(i) === -1 ? (
										{ whiteSpace: 'pre-wrap', cursor: 'pointer' }
									) : (
										{
											cursor: 'pointer',
											whiteSpace: 'pre-wrap',
											color: 'grey',
											borderBottom: 'grey',
											borderBottomStyle: 'dotted',
											borderBottomWidth: 1
										}
									)
								}
								onClick={({ target: { innerText } }) => {
									if (innerText.match(isAlfaNum)) {
										const idx = principal.aCompletar.indexOf(i)
										var res = [ ...principal.aCompletar ]
										if (idx === -1) {
											res.push(i)
										} else {
											res.splice(idx, 1)
										}
										dispatch(
											onChangeModulo({
												name: 'formulario',
												value: { ...formulario, principal: { ...principal, aCompletar: res } }
											})
										)
									}
								}}
							>
								{p}
							</Typography>
						))}
					</div>
					<Typography
						style={{
							margin: '0px 14px',
							marginBottom: 10,
							fontSize: '0.75rem',
							letterSpacing: '0.03333em',
							color: 'rgba(0, 0, 0, 0.54)',
							fontWeight: 400
						}}
					>
						Seleccione las palabras a completar
					</Typography>
					<FormControlLabel
						control={
							<Switch
								color='primary'
								size={'medium'}
								checked={principal.mayus}
								onChange={({ target: { name, checked } }) =>
									dispatch(
										onChangeModulo({
											name: 'formulario',
											value: { ...formulario, principal: { ...principal, [name]: checked } }
										})
									)}
								name='mayus'
							/>
						}
						label='Evaluar mayúsculas y minúsculas'
					/>
					<FormControlLabel
						control={
							<Switch
								color='primary'
								size={'medium'}
								checked={principal.tilde}
								onChange={({ target: { name, checked } }) =>
									dispatch(
										onChangeModulo({
											name: 'formulario',
											value: { ...formulario, principal: { ...principal, [name]: checked } }
										})
									)}
								name='tilde'
							/>
						}
						label='Evaluar acentos'
					/>
				</React.Fragment>
			) : (
				<TextField
					className={classes.input}
					name='texto'
					value={principal.texto}
					variant='filled'
					onChange={({ target: { name, value } }) =>
						dispatch(
							onChangeModulo({
								name: 'formulario',
								value: { ...formulario, principal: { ...principal, [name]: value } }
							})
						)}
					label='Texto completo'
					multiline
					rowsMax={10}
					rows={4}
					required
					error={moduloError && !principal.texto}
					helperText='Luego de completar el texto haga click en siguiente para seleccionar las palabras a completar'
				/>
			)}
			<Button
				color='primary'
				variant='contained'
				disabled={!(principal.texto && principal.texto.length > 0)} //Revisar minimo de caracteres para poder seleccionar
				onClick={() => {
					/* primero veo con que empieza
						busco la primera letraonumero
						luego busco el primer no letra numero
						corto palabra y agrego al arreglo
						busco primera letra o numero
						agrego al arreglo los espacios 
						repetir */
					var parsed = []
					const t = principal.texto
					if (!principal.siguiente) {
						var letra = Boolean(t[0].match(isAlfaNum))
						var ini = 0
						for (var i = 0; principal.texto.length > i; i++) {
							if (t[i].match(isAlfaNum)) {
								if (!letra) {
									//encontre donde terminan los separadores
									parsed.push(t.slice(ini, i))
									ini = i
									letra = true //ahora leo letras o numeros
								}
							} else {
								if (letra) {
									//encontre donde termina la palabra
									parsed.push(t.slice(ini, i))
									ini = i
									letra = false //ahora leo separadores
								}
							}
						}
						parsed.push(t.slice(ini, i))
					}
					dispatch(
						onChangeModulo({
							name: 'formulario',
							value: {
								...formulario,
								principal: {
									...principal,
									siguiente: !principal.siguiente,
									textoCompleto: parsed,
									aCompletar: []
								}
							}
						})
					)
				}}
			>
				{principal.siguiente ? 'Volver' : 'Siguiente'}
			</Button>
		</React.Fragment>
	)
}

export function CompletarFraseRender(props) {
	const { modulo, classes, guardarRespuesta } = props
	const rol = useSelector(state => state.login.rol)
	const data = useSelector(state => state.modulos.data[modulo.id] || {})
	const infoPermisos = useSelector(state => state.permisos)
	const responderPermiso = hasPermission(rol, infoPermisos, permisos.RESPONDER_MODULOS)
	const dispatch = useDispatch()

	const comparar = (res, sol) => {
		const normalize = (function() {
			var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç',
				to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc',
				mapping = {}

			for (var i = 0, j = from.length; i < j; i++) mapping[from.charAt(i)] = to.charAt(i)

			return function(str) {
				var ret = []
				for (var i = 0, j = str.length; i < j; i++) {
					var c = str.charAt(i)
					if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c])
					else ret.push(c)
				}
				return ret.join('')
			}
		})()
		var res2 = res
		var sol2 = sol
		if (!modulo.principal.mayus) {
			res2 = res2.toLowerCase()
			sol2 = sol2.toLowerCase()
		}
		if (!modulo.principal.tilde) {
			res2 = normalize(res2)
			sol2 = normalize(sol2)
		}
		return res2 === sol2 ? true : false
	}
	const respuesta = modulo.respuesta ? modulo.respuesta.respuesta : null
	var resValida = true
	if (respuesta)
		modulo.principal.aCompletar.forEach(e => {
			resValida = Boolean(respuesta[e]) && resValida
		})
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: 10,
					flexWrap: 'wrap'
				}}
			>
				{modulo.principal.textoCompleto.map(
					(s, i) =>
						!resValida || modulo.principal.aCompletar.indexOf(i) === -1 ? (
							<Typography
								className={classes.notSelectable}
								style={{ whiteSpace: 'pre-wrap', marginBottom: 22 }}
								key={'asdasww' + i}
							>
								{s}
							</Typography>
						) : (
							<TextField
								className={classes.notSelectable}
								key={'asdasww' + i}
								// eslint-disable-next-line
								className={classes.input}
								style={{
									margin: '0px 5px',
									marginBottom: respuesta ? (comparar(respuesta[`${i}`], s) ? 22 : 0) : 22
								}}
								inputProps={{
									style: {
										borderBottomColor: respuesta
											? comparar(respuesta[`${i}`], s) ? green[500] : red[500]
											: 'inherit',
										color: respuesta
											? comparar(respuesta[`${i}`], s) ? green[500] : red[500]
											: 'inherit'
									}
								}}
								helperText={
									respuesta && comparar(respuesta[`${i}`], s) ? '' : respuesta ? 'Rta: ' + s : ''
								}
								name={`${i}`}
								value={respuesta ? respuesta[`${i}`] : data[`${i}`] || ''}
								variant='standard'
								onChange={({ target: { name, value } }) =>
									dispatch(
										onChangeRespuestaModulo({ name: modulo.id, value: { ...data, [name]: value } })
									)}
								label=''
								disabled={respuesta ? true : responderPermiso ? false : true}
							/>
						)
				)}
			</div>
			{modulo.principal.mayus ? (
				<Typography style={{ color: 'grey' }} variant='caption'>
					Nota: Se considerarán mayúsculas y minúsculas
				</Typography>
			) : null}
			{modulo.principal.tilde ? (
				<Typography style={{ color: 'grey' }} variant='caption'>
					Nota{modulo.principal.mayus ? '2' : ''}: Se considerarán los acentos
				</Typography>
			) : null}
			<Button
				disabled={modulo.respuesta ? true : responderPermiso ? false : true}
				style={{ marginBottom: 10, alignSelf: 'flex-end' }}
				color='primary'
				variant='contained'
				onClick={() => guardarRespuesta({ moduloId: modulo.id, data: { respuesta: data } })}
			>
				Responder
			</Button>
		</div>
	)
}

export const completarFraseValidar = principal =>
	principal.aCompletar && principal.aCompletar.length > 0 ? true : false
