import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	InputAdornment,
	makeStyles,
	MenuItem,
	TextField,
	Tooltip,
	Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { tamIcono, validateSize } from '../../../constants'
import { openSnack } from '../../../actions/feedback/openSnack'
import { useDispatch, useSelector } from 'react-redux'
import { green, grey, red } from '@material-ui/core/colors'
import { urlCurso, urlTematicas } from '../../../api/urls'
import { apiGet, apiGetWithParams, apiPost, apiPut } from '../../../api'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { encodeBase64 } from '../../../api/encoding'
import { Check, ReplayRounded, SearchRounded, SettingsRounded } from '@material-ui/icons'
import TematicasDialog from './TematicasDialog'

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	formulario: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: '100%',
		margin: '10px'
	},
	columna: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '50%',
		margin: '0px 20px'
	},
	image: {
		width: 150,
		height: 150,
		/* los siguientes valores son independientes del tamaño del círculo */
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50%',
		borderRadius: '50%',
		backgroundSize: '100% auto',
		border: '1px solid ' + grey['300']
	},
	descInput: {
		width: 'calc(100% - 40px)',
		marginLeft: 20,
		marginRight: 20
	},
	editTooltip: {
		backgroundColor: 'white',
		color: 'black',
		maxWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid ' + theme.palette.primary.main
	},
	editTooltipArrow: {
		color: theme.palette.primary.main
	}
}))

//const niveles = [ { id: 0, nombre: 'Principiante' }, { id: 1, nombre: 'Intermedio' }, { id: 2, nombre: 'Avanzado' } ]
//const idiomas = [ { id: 0, nombre: 'Español' } ]
const autoasistidos = [
	{ id: -1, nombre: 'Seleccionar una opción' },
	{ id: false, nombre: 'No' },
	{ id: true, nombre: 'Si' }
]
const contenidosMultimedia = [
	{ id: 0, nombre: 'Sin contenido' },
	{ id: 1, nombre: 'Audio' },
	{ id: 2, nombre: 'Video' },
	{ id: 3, nombre: 'Audio y video' }
]
const tiposAcceso = [ { id: 0, nombre: 'Gratuito' }, { id: 1, nombre: 'Pago' } ]
const breveDescLimite = 140

export default function CrearCursoDialog(props) {
	const { open, onClose, editar, curso } = props
	const classes = useStyles()
	const nuevoCursoDefault = {
		nombre: '',
		tematica: -1,
		autoasistido: -1,
		duracion: 0,
		idioma: -1,
		contenidoMultimedia: 0,
		nivel: -1,
		tipoAcceso: 0,
		cursosSugeridos: [],
		conocimientosPrevios: '',
		icono: undefined,
		descripcion: '',
		error: false,
		descripcion: '',
		breveDescripcion: '',
		correlativo_padre: undefined,
		nombreCursoCorrelativo: '',
		cursoCorrelativo: {},
		idsSugeridos: [],
		nombreCursosSugeridos: '',
		iconoModificado: false
		//Cantidad de evaluaciones, cantidad de unidades, cantidad de material en pdf
	}
	const [
		{
			nombre,
			tematica,
			autoasistido,
			duracion,
			idioma,
			contenidoMultimedia,
			nivel,
			tipoAcceso,
			cursoCorrelativo,
			cursosSugeridos,
			conocimientosPrevios,
			icono,
			error,
			descripcion,
			breveDescripcion,
			nombreCursoCorrelativo,
			correlativo_padre,
			nombreCursosSugeridos,
			idsSugeridos,
			iconoModificado
		},
		setNuevoCurso
	] = useState(nuevoCursoDefault)
	const [ tematicas, setTematicas ] = useState([ { id: -1, nombre: 'Seleccionar una opción' } ])
	const [ openTematicas, setOpenTematicas ] = useState(false)
	const [ idiomas, setIdiomas ] = useState([ { id: -1, nombre: 'Seleccionar una opción' } ])
	const [ niveles, setNiveles ] = useState([ { id: -1, nombre: 'Seleccionar una opción' } ])
	const [ cursos, setCursos ] = useState([])
	const [ sugeridos, setSugeridos ] = useState([])
	const [ timer, setTimer ] = useState(-1)
	const defaultImagePlaceholder = useSelector(state => state.metadata.imagePlaceholder)
	const [ imagenPreview, setImagenPreview ] = useState(defaultImagePlaceholder)
	const dispatch = useDispatch()

	useEffect(() => {
		//hacer fetch de cursos sugeridos
		if (editar) {
			dispatch(startLoading())
			apiGetWithParams(urlCurso, { ids: curso.idsSugeridos }).then(r => {
				if (r.status === 'OK') setNuevoCurso(ps => ({ ...ps, cursosSugeridos: r.data }))
				else
					dispatch(openSnack({ texto: 'error al obtener informacion sobre cursos sugeridos', tipo: 'error' }))
				dispatch(endLoading())
			})
		}
		dispatch(startLoading())
		apiGetWithParams(urlCurso, { metadataNuevo: true }).then(r => {
			if (r.status === 'OK') {
				setIdiomas([ { id: -1, nombre: 'Seleccionar una opción' }, ...r.data.idiomas ])
				setNiveles([ { id: -1, nombre: 'Seleccionar una opción' }, ...r.data.dificultades ])
				setTematicas([ { id: -1, nombre: 'Seleccionar una opción' }, ...r.data.tematicas ])
				if (editar) {
					setNuevoCurso(ps => ({
						...ps,
						...curso,
						nombreCursoCorrelativo: curso.correlativo_padre ? curso.cursoCorrelativo.nombre : ''
					}))
					setImagenPreview(curso.icono)
				}
			} else dispatch(openSnack({ texto: 'error al obtener los datos para crear curso', tipo: 'error' }))
			dispatch(endLoading())
		})
	}, [])

	const getTematicas = () => {
		dispatch(startLoading())
		apiGet(urlTematicas).then(r => {
			if (r.status === 'OK') {
				setTematicas([ { id: -1, nombre: 'Seleccionar una opción' }, ...r.data ])
			} else dispatch(openSnack({ texto: 'error al obtener las tematicas', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const onCloseDialog = b => {
		setNuevoCurso(nuevoCursoDefault)
		onClose(b)
	}

	const openSelector = () => {
		document.getElementById('path').click()
	}

	const guardar = () => {
		if (
			nombre &&
			tematica >= 0 &&
			autoasistido >= 0 &&
			duracion &&
			idioma >= 0 &&
			contenidoMultimedia >= 0 &&
			nivel >= 0 &&
			tipoAcceso >= 0 &&
			conocimientosPrevios &&
			(icono || editar) &&
			breveDescripcion
		) {
			const api = editar ? apiPut : apiPost
			const dopost = e => {
                dispatch(startLoading())
				api(urlCurso, {
					cursoId: editar ? curso.id : undefined,
					nombre,
					tematica,
					autoasistido,
					duracion,
					idioma,
					contenidoMultimedia,
					nivel,
					tipoAcceso,
					correlativaPadre: correlativo_padre,
					cursosSugeridos: idsSugeridos,
					conocimientosPrevios,
					icono: e ? e.target.result : '',
					descripcion,
					breveDescripcion,
					editarIcono: iconoModificado
				}).then(r => {
                    dispatch(endLoading())
					if (r.status === 'OK') {
						dispatch(openSnack({ texto: 'Curso creado con éxito', tipo: 'success' }))
						onCloseDialog(true)
					} else dispatch(openSnack({ texto: 'Error al crear curso', tipo: 'error' }))
				})
			}
			if (editar && !iconoModificado) dopost()
			else if (icono.size) encodeBase64(icono, dopost)
			else dispatch({ texto: 'Error al procesar la imagen', tipo: 'error' })
		} else {
			dispatch(openSnack({ texto: 'Falta completar uno o mas campos obligatorios', tipo: 'info' }))
			setNuevoCurso(ps => ({ ...ps, error: true }))
		}
	}

	const buscar = (value, name) => {
		console.log(name)
		apiGetWithParams(urlCurso, { search: true, value }).then(r => {
			if (r.status === 'OK')
				if (name === 'nombreCursosSugeridos') setSugeridos(r.data)
				else setCursos(r.data)
			else dispatch(openSnack({ texto: 'Error al obtener lista de cursos', tipo: 'error' }))
		})
	}

	const onChangeCursoNombre = e => {
		const { value, name } = e.target
		let timeout = -1
		if (value && value.length > 3) {
			clearTimeout(timer)
			timeout = setTimeout(() => {
				buscar(value, name)
			}, 300)
		}
		let resetProps = {}
		if (name === 'nombreCursoCorrelativo') resetProps = { cursoCorrelativo: {}, correlativo_padre: undefined }
		setNuevoCurso(ps => ({ ...ps, [name]: value, ...resetProps }))
		setTimer(timeout)
	}

	const deshacerIcono = () => {
		setNuevoCurso(ps => ({ ...ps, iconoModificado: false, icono: curso.icono }))
		setImagenPreview(curso.icono)
	}

	if (!open) return null

	const columna1 = [
		{
			props: {
				label: 'Nombre',
				value: nombre,
				name: 'nombre',
				fullWidth: true,
				required: true,
				error: error && !nombre,
				select: false
			},
			selectValues: null
		},
		{
			props: {
				label: 'Tematica',
				value: tematica,
				name: 'tematica',
				fullWidth: true,
				required: true,
				error: error && tematica < 0,
				select: true,
				InputProps: {
					startAdornment: (
						<InputAdornment position='start'>
							<IconButton color='primary' onClick={() => setOpenTematicas(true)}>
								<SettingsRounded />
							</IconButton>
						</InputAdornment>
					)
				}
			},
			selectValues: tematicas
		},
		{
			props: {
				label: 'Autoasistido',
				value: autoasistido,
				name: 'autoasistido',
				fullWidth: true,
				required: true,
				error: error && autoasistido < 0,
				select: true
			},
			selectValues: autoasistidos
		},
		{
			props: {
				label: 'Duración (en horas)',
				value: duracion,
				name: 'duracion',
				fullWidth: true,
				required: true,
				error: error && !duracion,
				select: false,
				type: 'number'
			},
			selectValues: null
		}
	]

	const columna2 = [
		{
			props: {
				label: 'Contenido audiovisual',
				value: contenidoMultimedia,
				name: 'contenidoMultimedia',
				fullWidth: true,
				required: true,
				error: error && !contenidoMultimedia,
				select: true
			},
			selectValues: contenidosMultimedia
		},
		{
			props: {
				label: 'Nivel',
				value: nivel,
				name: 'nivel',
				fullWidth: true,
				required: true,
				error: error && nivel < 0,
				select: true
			},
			selectValues: niveles
		},
		{
			props: {
				label: 'Tipo de acceso',
				value: tipoAcceso,
				name: 'tipoAcceso',
				fullWidth: true,
				required: true,
				error: error && !tipoAcceso,
				select: true
			},
			selectValues: tiposAcceso
		},
		{
			props: {
				label: 'Idioma',
				value: idioma,
				name: 'idioma',
				fullWidth: true,
				required: true,
				error: error && idioma < 0,
				select: true
			},
			selectValues: idiomas
		}
	]

	return (
		<React.Fragment>
			<Dialog open={open} maxWidth='md' fullWidth onClose={onCloseDialog}>
				<DialogTitle disableTypography>
					<Typography variant='h6'>{editar ? 'Editar' : 'Nuevo'} curso</Typography>
				</DialogTitle>
				<Divider />
				<DialogContent className={classes.content}>
					<Box>
						<Button
							className={classes.image}
							style={{ backgroundImage: `url(${imagenPreview})` }}
							onClick={openSelector}
						>
							<input
								accept='image/*'
								style={{ display: 'none' }}
								id='path'
								type='file'
								onChange={({ target: { files } }) => {
									if (files[0]) {
										if (validateSize(files[0], tamIcono * 1024 * 1024)) {
											setNuevoCurso(ps => ({ ...ps, icono: files[0], iconoModificado: true }))
											setImagenPreview(URL.createObjectURL(files[0]))
										} else
											dispatch(
												openSnack({
													texto: 'El tamaño de la imagen no pude superar ' + tamIcono + 'mb',
													tipo: 'info'
												})
											)
									}
								}}
							/>
						</Button>
					</Box>
					<Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
						<Typography color={error && !icono ? 'secondary' : 'inherit'} align='center'>
							{icono ? icono.name : 'Seleccione una imagen'}
						</Typography>
						{iconoModificado ? (
							<Tooltip
								classes={{ tooltip: classes.editTooltip, arrow: classes.editTooltipArrow }}
								placement='top'
								title={'Deshacer cambiar icono'}
								arrow
							>
								<IconButton onClick={deshacerIcono} color='secondary'>
									<ReplayRounded />
								</IconButton>
							</Tooltip>
						) : null}
					</Box>
					<Box className={classes.formulario}>
						<Box className={classes.columna}>
							{columna1.map(c => (
								<TextField
									key={c.props.label}
									{...c.props}
									margin='dense'
									variant='filled'
									onChange={({ target: { value, name } }) => {
										if (name === 'tematica')
											setNuevoCurso(ps => ({
												...ps,
												cursoCorrelativo: {},
												nombreCursoCorrelativo: '',
												correlativo_padre: undefined
											}))
										setNuevoCurso(ps => ({ ...ps, [name]: value }))
									}}
								>
									{c.selectValues ? (
										c.selectValues.map(v => (
											<MenuItem value={v.id} key={v.id}>
												{v.nombre}
											</MenuItem>
										))
									) : null}
								</TextField>
							))}
							<Autocomplete
								value={cursosSugeridos}
								multiple
								freeSolo
								fullWidth
								filterSelectedOptions
								options={sugeridos}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => {
										console.log(option, getTagProps({ index }))
										return (
											<Chip variant='default' label={option.nombre} {...getTagProps({ index })} />
										)
									})}
								renderOption={o => <React.Fragment>{o.nombre + ` (${o.tematica})`}</React.Fragment>}
								getOptionLabel={o => o.nombre || ''}
								renderInput={params => (
									<TextField
										{...params}
										margin='dense'
										label={'Cursos sugeridos'}
										variant='filled'
										fullWidth
										name='nombreCursosSugeridos'
										helperText={
											idsSugeridos.length > 0 ? 'Curso seleccionado' : 'Curso no seleciconado'
										}
									/>
								)}
								inputValue={nombreCursosSugeridos}
								onInputChange={e => (e ? onChangeCursoNombre(e) : null)}
								onChange={(_, v) => {
									setNuevoCurso(ps => ({
										...ps,
										cursosSugeridos: v || [],
										nombreCursosSugeridos: '',
										idsSugeridos: v ? v.map(e => e.id) : []
									}))
								}}
							/>
						</Box>
						<Box className={classes.columna}>
							{columna2.map(c => (
								<TextField
									key={c.props.label}
									{...c.props}
									margin='dense'
									variant='filled'
									onChange={({ target: { value, name } }) =>
										setNuevoCurso(ps => ({ ...ps, [name]: value }))}
								>
									{c.selectValues ? (
										c.selectValues.map(v => (
											<MenuItem value={v.id} key={v.id}>
												{v.nombre}
											</MenuItem>
										))
									) : null}
								</TextField>
							))}
							<Autocomplete
								value={cursoCorrelativo}
								freeSolo
								fullWidth
								options={cursos.filter(e => e.tematicaId === tematica)}
								renderOption={o => <React.Fragment>{o.nombre + ` (${o.tematica})`}</React.Fragment>}
								getOptionLabel={o => o.nombre || ''}
								renderInput={params => (
									<TextField
										{...params}
										margin='dense'
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment style={{ margin: 0 }} position='start'>
													{correlativo_padre ? (
														<Check style={{ color: green[600] }} />
													) : (
														<SearchRounded style={{ color: red[600] }} />
													)}
												</InputAdornment>
											)
										}}
										label={'Curso correlativo'}
										variant='filled'
										fullWidth
										disabled={tematica < 1}
										name='nombreCursoCorrelativo'
										helperText={correlativo_padre ? 'Curso seleccionado' : 'Curso no seleciconado'}
									/>
								)}
								disabled={tematica < 1}
								inputValue={nombreCursoCorrelativo}
								onInputChange={e => (e ? onChangeCursoNombre(e) : null)}
								onChange={(_, v) => {
									setNuevoCurso(ps => ({
										...ps,
										cursoCorrelativo: v || {},
										nombreCursoCorrelativo: v && v.nombre ? v.nombre : '',
										correlativo_padre: v ? v.id : undefined
									}))
								}}
							/>
						</Box>
					</Box>
					<TextField
						className={classes.descInput}
						name='breveDescripcion'
						value={breveDescripcion}
						error={error && !descripcion}
						onChange={({ target: { value, name } }) => {
							if (value.length > breveDescLimite)
								dispatch(openSnack({ texto: 'Límite de caracteres alcanzado', tipo: 'info' }))
							else setNuevoCurso(ps => ({ ...ps, [name]: value }))
						}}
						label='Breve descripción'
						fullWidth
						required
						rows={2}
						multiline
						variant='filled'
						rowsMax={4}
						margin='dense'
						helperText={`${breveDescLimite - breveDescripcion.length} caracteres disponibles`}
					/>
					<TextField
						className={classes.descInput}
						name='descripcion'
						value={descripcion}
						onChange={({ target: { value, name } }) => setNuevoCurso(ps => ({ ...ps, [name]: value }))}
						label='Descripción (opcional)'
						fullWidth
						rows={2}
						multiline
						variant='filled'
						rowsMax={8}
						margin='dense'
					/>
					<TextField
						className={classes.descInput}
						name='conocimientosPrevios'
						value={conocimientosPrevios}
						error={error && !conocimientosPrevios}
						onChange={({ target: { value, name } }) => setNuevoCurso(ps => ({ ...ps, [name]: value }))}
						label='Conocimientos previos'
						fullWidth
						rows={2}
						multiline
						required
						variant='filled'
						rowsMax={8}
						margin='dense'
					/>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button color='secondary' onClick={() => onCloseDialog(false)}>
						Cancelar
					</Button>
					<Button color='primary' variant='contained' onClick={guardar}>
						Guardar
					</Button>
				</DialogActions>
			</Dialog>
			<TematicasDialog
				open={openTematicas}
				tematicas={tematicas}
				onClose={() => setOpenTematicas(false)}
				getTematicas={getTematicas}
			/>
		</React.Fragment>
	)
}
