import React from 'react'
import { TextField } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'

export function CodigoPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<TextField
			className={classes.input}
			name='texto'
			value={principal.texto}
			variant='filled'
			onChange={({ target: { name, value } }) =>
				dispatch(
					onChangeModulo({
						name: 'formulario',
						value: { ...formulario, principal: { ...principal, [name]: value } }
					})
				)}
			label='Texto'
			multiline
			rowsMax={10}
			rows={4}
			required
			error={moduloError && !principal.texto}
		/>
	)
}

export function CodigoRender(props) {
	const { modulo } = props
	return (
		<code
			style={{
				whiteSpace: 'pre-wrap',
				backgroundColor: '#f7f7f7',
				padding: 5,
				border: '1px solid #cfcfcf',
				borderRadius: 4
			}}
		>
			{modulo.principal.texto}
		</code>
	)
}

export const codigoValidar = principal => (principal.texto ? true : false)
