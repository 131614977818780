import { Button, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import styles from './styles.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { login } from '../../../actions/login/login'
import { openSnack } from '../../../actions/feedback/openSnack'
import { urlLogin } from '../../../api/urls'
import { apiPost } from '../../../api'

const Formulario = () => {
	const [ state, setState ] = useState({ username: '', password: '', loading: false })
	const nombre = useSelector(state => state.metadata.nombre)
	const history = useHistory()
	const dispatch = useDispatch()

	const onLogin = () => {
		const { username, password } = state
		setState(ps => ({ ...ps, loading: true }))
		apiPost(urlLogin, { username, password })
			.then(r => {
				dispatch(login(r))
				if (r.status === 'INCORRECT') {
					dispatch(openSnack({ texto: 'Usuario o contraseña incorrecto', tipo: 'info' }))
					setState(ps => ({ ...ps, loading: false }))
				} else if (r.status === 'OK') history.push('/dashboard')
				else {
					dispatch(openSnack({ texto: 'Error al iniciar sesion', tipo: 'error' }))
					setState(ps => ({ ...ps, loading: false }))
				}
			})
			.catch(e => console.log(e))
	}

	const onkeyPress = key => {
		if (key.which === 13 || key.keyCode === 13) {
			onLogin()
			return false // returning false will prevent the event from bubbling up.
		} else return true
	}

	const { username, password, loading } = state
	return (
		<div className={styles.form_container}>
			<div className={styles.formulario}>
				<div className={styles.texto_formulario}>
					<h1>Bienvenido a CCOT Virtual</h1>
					<p>Descubre y domina nuevas habilidades en ti.</p>
					<p>
						Las mejores instituciones dedicadas a la educación y tecnología para ofrecerte contenido de
						calidad en una plataforma optimizada para tu aprendizaje
					</p>
				</div>
				<div className={styles.campos_formulario}>
					<form action=''>
						<TextField
							style={{ marginBottom: 10 }}
							name='username'
							value={username}
							variant='outlined'
							onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
							label='Usuario'
							fullWidth
							onKeyPress={e => onkeyPress(e)}
						/>
						<TextField
							style={{ marginBottom: 10 }}
							name='password'
							value={password}
							variant='outlined'
							onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
							label='Contraseña'
							type='password'
							fullWidth
							onKeyPress={e => onkeyPress(e)}
						/>
						<span className={styles.form_buttons}>
							<Button
								variant='contained'
								color='primary'
								//className={styles.inicio_sesion}
								onClick={onLogin}
								disabled={loading}
							>
								Iniciar Sesión
							</Button>
						</span>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Formulario
