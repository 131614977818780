import React from 'react'
import { httpDigitalOcanStorage } from '../httpMedia'
import styles from './styles.module.css'

const Navbar = (props) => {


    console.log(props.isTop)

    const styleOnScroll = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#ffffff',
        backgroundImage: 'url(https://storage.googleapis.com/agl-01/wedrim-landing-assets/Elipse%205.svg)', 
        backgroundPosition: '251px -1547px',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        top: '0',
        margin: '1% 2.5%',
        width: '95%',
        height: '9vh',
        padding: '2%',
        borderRadius: '50px',
    }

    return (
        <nav className={styles.navbar} style={props.isTop?{}:styleOnScroll}>
            <img src={`${httpDigitalOcanStorage}ogi/Gradient-OGItech-01.png`} alt=""/> 
            <div className={styles.navbar_btn}>
                {/* <ul>
                    <li><a href='#faqs'> FAQ</a></li>
                    <li><a href='#contacto'> Contacto</a></li>
                    <li><a href='/#'> Blog</a></li>
                </ul> */}
            </div>
        </nav>
    )
}

export default Navbar
