import React from 'react'
import { FormControlLabel, Switch, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { TextoPrincipal } from './renders/Texto'
import { openSnack } from '../../../../actions/feedback/openSnack'
import { onChangeModulo } from '../../../../actions/modulos/onChangeModulo'
import { ArchivoPrincipal } from './renders/Archivo'
import { ImagenPrincipal } from './renders/Imagen'
import { MultipleChoicePrincipal } from './renders/MultipleChoice'
import { VideoPrincipal } from './renders/Video'
import { HipervinculoPrincipal } from './renders/Hipervinculo'
import { CodigoPrincipal } from './renders/Codigo'
import { CompletarFrasePrincipal } from './renders/CompletarFrase'
import { SubirActividadResueltaPrincipal } from './renders/SubirActividadResuelta'

export default function RenderDialog(props) {
	const dispatch = useDispatch()
	const { titulo, formulario } = useSelector(state => state.modulos.editing)
    const { classes, modulo } = props
    
    console.log(classes)

	const moduloSelector = modulo => {
		switch (modulo.nombre) {
			case 'Texto':
				return <TextoPrincipal classes={classes} />
			case 'Archivo':
				return <ArchivoPrincipal classes={classes} />
			case 'Imagen':
				return <ImagenPrincipal classes={classes} />
            case 'Pregunta multiple choice':
                return <MultipleChoicePrincipal classes={classes} />
            case 'Video': 
                return <VideoPrincipal classes={classes} />
            case 'Hipervinculo':
                return <HipervinculoPrincipal classes={classes} />
            case 'Codigo':
                return <CodigoPrincipal classes={classes} />
            case 'Completar la frase': 
                return <CompletarFrasePrincipal classes={classes} />
/*             case 'Subir actividad resuelta':
                return <SubirActividadResueltaPrincipal classes={classes} /> */
			default:
				return null
		}
	}

	return (
		<React.Fragment>
			<TextField
				className={classes.input}
				name='titulo'
				value={titulo}
				variant='filled'
				onChange={({ target: { value, name } }) => {
					if (value.length > 60)
						dispatch(
							openSnack({
								texto: 'El tiítulo no puede superar los 60 caracteres',
								tipo: 'info'
							})
						)
					else dispatch(onChangeModulo({ name, value }))
				}}
				label='Título (Opcional)'
			/>
			{moduloSelector(modulo)}
			{modulo.obligatorio ? (
				<FormControlLabel
					control={
						<Switch
							color='primary'
							size={'medium'}
							checked={formulario.obligatorio}
							onChange={({ target: { name, checked } }) =>
								dispatch(
									onChangeModulo({ name: 'formulario', value: { ...formulario, [name]: checked } })
								)}
							name='obligatorio'
						/>
					}
					label='Modulo obligatorio'
				/>
			) : (
				() => {
					dispatch(onChangeModulo({ name: 'formulario', value: { ...formulario, obligatorio: false } }))
					return null
				}
			)}
		</React.Fragment>
	)
}
