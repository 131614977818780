import { blue, green, indigo, orange, pink, purple, red, teal } from '@material-ui/core/colors'
import {
	CodeRounded,
	HelpOutlineRounded,
	InsertDriveFileRounded,
	InsertPhotoRounded,
	LinkRounded,
    PublishRounded,
	SpellcheckRounded,
	SubjectRounded,
	VideoCallRounded
} from '@material-ui/icons'

export default [
	{ nombre: 'Texto', icono: SubjectRounded, color: green, obligatorio: false },
	{ nombre: 'Archivo', icono: InsertDriveFileRounded, color: red, obligatorio: false },
	{ nombre: 'Imagen', icono: InsertPhotoRounded, color: orange, obligatorio: false },
	{ nombre: 'Hipervinculo', icono: LinkRounded, color: blue, obligatorio: false },
	{ nombre: 'Video', icono: VideoCallRounded, color: blue, obligatorio: false },
	{ nombre: 'Pregunta multiple choice', icono: HelpOutlineRounded, color: purple, obligatorio: true },
	{ nombre: 'Codigo', icono: CodeRounded, color: teal, obligatorio: false },
    { nombre: 'Completar la frase', icono: SpellcheckRounded, color: indigo, obligatorio: true },
    //{ nombre: 'Subir actividad resuelta', icono: PublishRounded, color: pink[500], obligatorio: true}
]

// En este archivo se listan los nombres de los modulos activos